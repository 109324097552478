import { forwardRef } from 'react';
import { IconProps } from '../types';

export const FileTransferIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M12.7 6.7H9.6c-.2 0-.3-.1-.3-.3V2.3c-.1-.2.1-.3.3-.3h1.8c.1 0 .2 0 .2.1L13 3.5v2.9c0 .1-.1.3-.3.3zM9.9 6h2.5V3.8l-1.2-1.2H9.9V6z"
                    fill={color}
                />
                <path
                    d="M12.7 4h-1.6c-.2 0-.3-.1-.3-.3V2.3c0-.2.1-.3.3-.3s.3.1.3.3v1h1.3c.2 0 .3.1.3.3s-.1.4-.3.4zm-5.6 9H4.3c-.1 0-.3-.1-.3-.3s.1-.3.3-.3H7c.2 0 .3.1.3.3.1.2-.1.3-.2.3z"
                    fill={color}
                />
                <path
                    d="M5.7 12.9c-.2 0-.3-.1-.3-.3v-.8c0-.2.1-.3.3-.3s.3.2.3.4v.8c0 .1-.1.2-.3.2zM9 11.2H2.5c-.2 0-.3-.1-.3-.3s.1-.3.3-.3H9c.2 0 .3.1.3.3s-.1.3-.3.3zM5.9 6.8c-.2 0-.3-.1-.3-.3v-.4c0-1.4 1.2-2.6 2.6-2.6.2 0 .3.1.3.3s-.1.4-.3.4c-1.1 0-1.9.9-1.9 1.9v.4c-.1.2-.2.3-.4.3z"
                    fill={color}
                />
                <path
                    d="M5.9 7.3 4.8 6.1c0-.1-.1-.1-.1-.2 0-.2.1-.3.3-.3.1 0 .2 0 .2.1l.7.7.7-.7c.1-.1.1-.1.2-.1s.2 0 .2.1c.1.1.1.1.1.2s0 .2-.1.2L5.9 7.3zM10 11c-.2 0-.3-.1-.3-.3s.1-.3.3-.3c1.1 0 1.9-.9 1.9-1.9V8c0-.2.1-.3.3-.3s.3.1.3.3v.4c.1 1.4-1.1 2.6-2.5 2.6z"
                    fill={color}
                />
                <path
                    d="M13.2 8.9c-.1 0-.2 0-.2-.1l-.7-.7-.7.7c-.1.1-.1.1-.2.1s-.2 0-.2-.1c-.1 0-.2-.1-.2-.2s0-.2.1-.2l1.1-1.1 1.1 1.1c.1.1.1.1.1.2s0 .2-.1.2c0 .1-.1.1-.1.1zm-4.8 3.2H3c-.6 0-1-.5-1-1V8c0-.6.5-1 1-1h.9c.2 0 .3.1.3.3 0 .2-.1.3-.3.3H3c-.2 0-.4.2-.4.4v3.2c0 .2.2.4.4.4h5.4c.2 0 .4-.2.4-.4V8c0-.2-.2-.4-.4-.4h-.7c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h.7c.6 0 1 .4 1 1v3.2c0 .5-.4.9-1 .9z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default FileTransferIcon;
