export const getArrayRange = (start: number, end: number): number[] =>
    Array(end - start + 1)
        .fill('')
        .map((__, idx) => start + idx);

export const hasDuplicatedElements = (array: unknown[]) =>
    array?.some?.((elem, index) => array.indexOf(elem) !== index);

export const difference = (a1?: unknown[] | null, a2?: unknown[] | null) => {
    const a2Set = new Set(a2 ?? []);
    return (a1 ?? []).filter((x) => !a2Set.has(x));
};

export const symmetricDifference = (a1?: unknown[] | null, a2?: unknown[] | null) =>
    difference(a1, a2).concat(difference(a2, a1));

export const intersection = (a1?: unknown[] | null, a2?: unknown[] | null) =>
    (a2 ?? []).filter((x) => (a1 ?? []).includes(x));
