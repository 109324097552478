import { forwardRef } from 'react';
import { IconProps } from '../types';

export const MenuIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <rect width="7.64" height="1.43" x="5.11" y="3.92" rx=".3" ry=".3" fill={color} />
            <rect width="7.64" height="1.43" x="3.68" y="6.78" rx=".3" ry=".3" fill={color} />
            <rect width="7.64" height="1.43" x="2.25" y="9.65" rx=".3" ry=".3" fill={color} />
        </svg>
    );
});

export default MenuIcon;
