import { forwardRef } from 'react';
import { IconProps } from '../types';

export const TemplatesIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M1.95 13.45h4.68c.22 0 .4-.18.4-.4V8.37c0-.22-.18-.4-.4-.4H1.95c-.22 0-.4.18-.4.4v4.68c0 .22.18.4.4.4Zm2.28-1.4H2.49v-.23h1.74v.23Zm1.91 0H4.4v-.23h1.74v.23ZM4.38 8.88h1.76v1.7H4.38v-1.7Zm-.01 1.96h1.74v.23H4.37v-.23Zm0 .49h1.74v.23H4.37v-.23Zm0 .98h1.46v.23H4.37v-.23ZM2.46 8.88h1.76v1.7H2.46v-1.7Zm0 1.96H4.2v.23H2.46v-.23Zm0 .49H4.2v.23H2.46v-.23Zm0 .98h1.46v.23H2.46v-.23Zm5.9 1.14h4.68c.22 0 .4-.18.4-.4V8.37c0-.22-.18-.4-.4-.4H8.36c-.22 0-.4.18-.4.4v4.68c0 .22.18.4.4.4Zm2.28-1.4H8.9v-.23h1.74v.23Zm.15-3.17h1.74v3.65h-1.74V8.88Zm-.15 1.7H8.9v-.23h1.74v.23ZM8.87 8.86h1.74v.23H8.87v-.23Zm0 .51h1.74v.23H8.87v-.23Zm0 .49h1.74v.23H8.87v-.23Zm0 .98h1.74v.23H8.87v-.23Zm0 .49h1.74v.23H8.87v-.23Zm0 .98h1.46v.23H8.87v-.23ZM1.95 7.04h4.68c.22 0 .4-.18.4-.4V1.95c0-.22-.18-.4-.4-.4H1.95c-.22 0-.4.18-.4.4v4.68c0 .22.18.4.4.4Zm2.28-1.4H2.49v-.23h1.74v.23Zm1.6.49H4.37V5.9h1.46v.23Zm.29-1.21v.23H4.38v-.23h1.74Zm-1.75-.26v-.23h1.74v.23H4.37Zm1.77.98H4.4v-.23h1.74v.23ZM2.46 2.47h3.66v1.7H2.46v-1.7Zm0 1.96H4.2v.23H2.46v-.23Zm0 .49H4.2v.23H2.46v-.23Zm0 .98h1.46v.23H2.46V5.9Zm5.5-3.95v4.68c0 .22.18.4.4.4h4.68c.22 0 .4-.18.4-.4V1.95c0-.22-.18-.4-.4-.4H8.36c-.22 0-.4.18-.4.4Zm.91.52h1.74v1.65H8.87V2.47Zm0 1.96h1.74v.23H8.87v-.23Zm0 .49h1.74v.23H8.87v-.23Zm1.46 1.21H8.87V5.9h1.46v.23Zm.31-.49H8.9v-.23h1.74v.23Zm1.61.49h-1.46V5.9h1.46v.23Zm.29-.49H10.8v-.23h1.74v.23Zm0-.49H10.8v-.23h1.74v.23Zm0-.49H10.8v-.23h1.74v.23Zm0-.49H10.8v-.23h1.74v.23Zm0-.49H10.8v-.23h1.74v.23Zm0-.49H10.8v-.23h1.74v.23Zm0-.49H10.8v-.23h1.74v.23Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default TemplatesIcon;
