import { forwardRef } from 'react';
import { IconProps } from '../types';

export const SupportIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M7.46 2.57a4.7 4.7 0 1 0 .002 9.402A4.7 4.7 0 0 0 7.46 2.57Zm-.07 6.72c-.21 0-.39-.17-.39-.39s.17-.39.39-.39.39.17.39.39-.17.39-.39.39Zm.35-1.41v.01c-.03.17-.18.3-.36.3s-.34-.14-.36-.32V7.8c.02-1.03 1.07-.87 1.07-1.39 0-.28-.21-.45-.57-.45-.5 0-.62.16-.72.42v.02c-.06.12-.17.2-.31.2-.19 0-.35-.16-.35-.35v-.02c0-.44.47-.99 1.4-.99.87 0 1.39.44 1.39 1.1 0 1.01-1.11.83-1.16 1.54Z"
                    fill={color}
                />
                <path
                    d="M13.32 5.53h-.04c-.66-2.5-2.72-4.38-5.24-4.64s-.02 0-.03 0H7.9C7.75.88 7.6.87 7.45.87c-.15 0-.31 0-.46.02h-.1c-.14.01-.28.03-.42.06-.04 0-.08.02-.13.02-.11.02-.21.04-.31.07-.08.02-.15.04-.22.06-.07.02-.15.05-.22.07-1.92.66-3.42 2.3-3.96 4.36-.61.02-1.1.52-1.1 1.14v1.54c0 .63.51 1.14 1.14 1.14h.05c.74 2.36 2.72 4.11 5.12 4.37.09.25.33.42.61.42.36 0 .65-.29.65-.65 0-.1-.03-.2-.07-.29v-.04h-.02c-.11-.2-.32-.33-.56-.33s-.45.13-.56.33c-2.13-.23-3.9-1.75-4.61-3.82h.42c0-.02-.02-.04-.03-.06a5.205 5.205 0 0 1-.11-3.76h-.37c.72-2.38 2.8-4.11 5.27-4.11.2 0 .39.01.58.03 2.22.24 4.06 1.88 4.69 4.08h-.38c.19.55.3 1.13.3 1.74 0 .74-.16 1.44-.43 2.08h1.1c.63 0 1.14-.51 1.14-1.14V6.66c0-.63-.51-1.14-1.14-1.14Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default SupportIcon;
