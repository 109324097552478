/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';

type AnyFunction = (...args: any) => any;

type DebounceReturnFunction<T extends AnyFunction> = (...args: Parameters<T>) => ReturnType<T>;

export const useDebounceFunction = <T extends AnyFunction>(func?: T, delay = 500): DebounceReturnFunction<T> => {
    const timeOutRef = useRef<NodeJS.Timeout | null>(null);

    const debounce = (...args: any[]): any => {
        if (timeOutRef.current) {
            clearTimeout(timeOutRef.current);
        }

        timeOutRef.current = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            func?.(...args);
        }, delay);
    };

    useEffect(
        () => () => {
            if (timeOutRef.current) {
                clearTimeout(timeOutRef.current);
            }
        },
        [],
    );

    return debounce;
};
