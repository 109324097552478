import { forwardRef } from 'react';
import { IconProps } from '../types';

export const SettingsIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M5.06 7.29c.34 0 .61-.27.61-.61s-.27-.61-.61-.61H3.54V3.86c0-.17-.14-.3-.3-.3s-.3.14-.3.3v2.21H1.42c-.34 0-.61.27-.61.61s.27.61.61.61h1.52v4.38c0 .17.14.3.3.3s.3-.14.3-.3V7.29h1.52Zm4.27 1.83H7.81V3.87c0-.17-.14-.3-.3-.3s-.3.14-.3.3v5.25H5.69c-.34 0-.61.27-.61.61s.27.61.61.61h1.52v1.33c0 .17.14.3.3.3s.3-.14.3-.3v-1.33h1.52c.34 0 .61-.27.61-.61s-.27-.61-.61-.61Zm4.26-6.09H9.93c-.34 0-.61.27-.61.61s.27.61.61.61h1.52v7.42c0 .17.14.3.3.3s.3-.14.3-.3V4.25h1.52c.34 0 .61-.27.61-.61s-.27-.61-.61-.61Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default SettingsIcon;
