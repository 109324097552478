import { forwardRef } from 'react';
import { IconProps } from '../types';

export const PuzzleIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M13.1 14.6H2c-.9 0-1.7-.8-1.7-1.7v-11C.4 1 1.1.2 2 .2h5.5c.1 0 .2 0 .2.1s.1.1.1.2v2.1c.2-.1.4-.1.7-.1.8 0 1.7.6 1.7 1.7 0 .2 0 .4-.1.5 0 .2-.2.2-.4.2s-.2-.2-.2-.4c0-.1.1-.2.1-.4C9.6 3.4 9 3 8.5 3c-.4 0-.6.1-.7.2-.1.2-.2.2-.3.2-.1 0-.2-.2-.2-.3V.8H2c-.6 0-1 .5-1 1.1V13c0 .6.4 1 1 1h11.1c.6 0 1.1-.5 1.1-1.1V7.7h-1.4c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h1.7c.2 0 .3.1.3.3V13c0 .9-.8 1.6-1.7 1.6zM10.8 10c-1.2 0-1.7-1.1-1.7-1.7 0-.2 0-.4.1-.6H7.6c-.2 0-.3-.1-.3-.3V5.3c0-.1.1-.2.2-.3h.2c.2.1.4.2.5.2.2 0 .3.2.2.3 0 .2-.2.3-.3.2-.1.1-.1.1-.2.1v1.4h1.8c.1 0 .2.1.3.2.1.1 0 .2 0 .3-.2.1-.3.4-.3.6 0 .3.3 1.1 1.1 1.1.7 0 .9-.6 1-.7.1-.2.2-.2.4-.2.2.1.2.2.2.4-.3.7-.9 1.1-1.6 1.1z"
                fill={color}
            />
            <path
                d="M7.6 7.7H5.2c-.1 0-.2-.1-.2-.2s0-.3.1-.3c.2-.2.3-.4.3-.7 0-.4-.4-1.1-1.1-1.1-.7 0-1.1.6-1.1 1.1 0 .3.1.5.3.7.1.1.1.2.1.3 0 .1-.2.2-.3.2H1.1c-.1 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h1.6c-.1-.2-.1-.4-.1-.6 0-.7.6-1.7 1.7-1.7S6 5.7 6 6.5c0 .2 0 .4-.1.6h1.7c.2 0 .3.1.3.3 0 .2-.2.3-.3.3z"
                fill={color}
            />
            <path
                d="M7.6 14.6c-.2 0-.3-.1-.3-.3v-1.7c-.2.1-.4.1-.6.1-.8 0-1.7-.6-1.7-1.7s1-1.7 1.7-1.7c.2 0 .4 0 .6.1v-2c0-.2.1-.3.3-.3.2 0 .3.1.3.3V10c0 .1-.1.2-.2.3-.1 0-.2 0-.3-.1-.2-.1-.4-.2-.7-.2-.4 0-1.1.4-1.1 1.1 0 .7.6 1.1 1.1 1.1.3 0 .5-.1.7-.3.1-.1.2-.1.3-.1.1 0 .2.2.2.3v2.3c0 .1-.1.2-.3.2zm3.8-5.4c-.9 0-1.7-.8-1.7-1.7 0-.1 0-.3.1-.4H8.2c-.1 0-.2 0-.2-.1-.1 0-.1-.1-.1-.2V4.6c0-.1.1-.2.2-.3.1 0 .2 0 .3.1.2.2.5.3.7.3.6 0 1.1-.5 1.1-1.1s-.5-1.1-1.1-1.1c-.3 0-.5.1-.7.3-.1.1-.3.1-.4.1-.1 0-.2-.2-.2-.3V-.1c0-.2.1-.3.3-.3h6c.7 0 1.2.5 1.2 1.2v6c0 .1 0 .2-.1.2-.1.1-.1.1-.2.1h-2c0 .1.1.3.1.4 0 1-.8 1.7-1.7 1.7zm-1.2-2.7c.1 0 .2.1.3.1.1.1.1.2 0 .3-.1.2-.2.4-.2.6 0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1c0-.2-.1-.4-.2-.6-.1-.1-.1-.2 0-.3.1-.1.1-.1.3-.1h2.2V.9c0-.3-.3-.6-.6-.6H8.5v1.8c.2-.1.4-.2.6-.2.9 0 1.7.8 1.7 1.7S10 5.3 9.1 5.3c-.2 0-.4 0-.6-.1v1.4l1.7-.1z"
                fill={color}
            />
        </svg>
    );
});

export default PuzzleIcon;
