import { forwardRef } from 'react';
import { IconProps } from '../types';

export const BigPhoneSmallHouseIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M6.62 1.9H4.17c-.12 0-.23.1-.23.23s.1.23.23.23h2.45c.12 0 .23-.1.23-.23s-.1-.23-.23-.23Zm-1.19 9.16c-.2 0-.36.16-.36.36s.16.36.36.36.36-.16.36-.36-.16-.36-.36-.36Z"
                    fill={color}
                />
                <path
                    d="m12.73 10.02-2.68-1.87a.678.678 0 0 0-.77 0l-.47.33V2.12c0-.67-.54-1.21-1.21-1.21H3.19c-.67 0-1.21.54-1.21 1.21v9.32c0 .67.54 1.21 1.21 1.21h3.93v1.2c0 .12.1.23.23.23h4.64c.12 0 .23-.1.23-.23v-2.62s.09.01.13.01a.674.674 0 0 0 .38-1.23Zm-10.3-7.9c0-.42.34-.76.76-.76h4.42c.42 0 .76.34.76.76v.76H2.43v-.76Zm0 1.21h5.93v5.46l-1.75 1.23c-.09.06-.15.14-.2.22H2.44V3.33Zm.76 8.87a.76.76 0 0 1-.76-.76v-.76h3.9a.67.67 0 0 0 .79.55v.97H3.19Zm7.03 1.43h-1.1v-1.61h1.1v1.61Zm1.54 0h-1.1v-1.84c0-.12-.1-.23-.23-.23H8.88c-.12 0-.23.1-.23.23v1.84h-1.1v-2.64h-.01l2.11-1.47 2.11 1.47h-.01v2.64Zm.77-2.93c-.04.06-.11.1-.18.1a.22.22 0 0 1-.13-.04L9.67 8.98l-2.55 1.78c-.1.07-.24.05-.31-.06-.07-.1-.05-.24.06-.31l2.68-1.87c.08-.05.18-.05.26 0l2.68 1.87c.1.07.13.21.06.31Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default BigPhoneSmallHouseIcon;
