import { forwardRef } from 'react';
import { IconProps } from '../types';

export const CalculatorIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M11.27 1.51H3.73c-.18 0-.33.15-.33.33v11.32c0 .18.15.33.33.33h7.55c.18 0 .33-.15.33-.33V1.84c0-.18-.15-.33-.33-.33ZM5.34 12.3H4.8c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm0-1.62H4.8c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm0-1.62H4.8c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm0-1.62H4.8c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm1.62 4.86h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm0-1.62h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm0-1.62h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm0-1.62h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm3.24 4.86H8.04c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h2.16c.12 0 .21.09.21.21s-.09.21-.21.21ZM8.04 8.64h.54c.12 0 .21.09.21.21s-.09.21-.21.21h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21Zm-.21-1.41c0-.12.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21h-.54c-.12 0-.21-.09-.21-.21Zm.21 3.02h.54c.12 0 .21.09.21.21s-.09.21-.21.21h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21Zm2.16.43h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm0-1.62h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm0-1.62h-.54c-.12 0-.21-.09-.21-.21s.09-.21.21-.21h.54c.12 0 .21.09.21.21s-.09.21-.21.21Zm.32-2.58H4.47V2.59h6.05v2.27Z"
                    fill={color}
                />
                <path
                    d="M9.66 4.21c.12 0 .21-.09.21-.21v-.54c0-.12-.09-.21-.21-.21s-.21.09-.21.21V4c0 .12.09.21.21.21Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default CalculatorIcon;
