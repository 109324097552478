import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ReportIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M12.32 4.31 9.05 1.04a.273.273 0 0 0-.19-.08H2.87c-.15 0-.27.12-.27.27v12.52c0 .15.12.27.27.27h9.25c.15 0 .27-.12.27-.27V4.51c0-.07-.03-.14-.08-.19Zm-1.83 7.95H4.5c-.23 0-.41-.18-.41-.41V5.59c0-.23.18-.41.41-.41s.41.18.41.41v3.64l1.48-1.48c.14-.14.35-.16.52-.05l1.34.9 1.8-1.92c.07-.08.18-.13.29-.13.1 0 .21.04.29.11.16.15.17.41.02.58L8.61 9.42c-.14.15-.36.17-.53.06l-1.35-.9-1.82 1.82v1.05h5.58c.23 0 .41.18.41.41s-.18.41-.41.41Zm-1.9-7.48V1.51l3.27 3.27H8.59Z"
                fill={color}
            />
        </svg>
    );
});

export default ReportIcon;
