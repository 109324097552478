import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ClickWebIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M3.1 3.24c.15 0 .28-.12.28-.28s-.12-.28-.28-.28-.28.12-.28.28.12.28.28.28Zm1.22 0c.15 0 .28-.12.28-.28s-.12-.28-.28-.28-.28.12-.28.28.12.28.28.28Zm1.23 0c.15 0 .28-.12.28-.28s-.12-.28-.28-.28-.28.12-.28.28.12.28.28.28Z"
                    fill={color}
                />
                <path
                    d="m14.04 12.49-1.73-1.93 1.14-.45a.28.28 0 0 0 .16-.23c0-.1-.05-.19-.15-.23L11.6 8.8V2.81c0-.6-.48-1.08-1.08-1.08H2.64c-.6 0-1.08.48-1.08 1.08v5.81c0 .6.48 1.08 1.08 1.08h5.84l1.14 3.41c.03.09.12.16.22.17.1 0 .19-.04.24-.13l.57-1.09 1.73 1.93c.05.05.12.08.19.08.06 0 .12-.02.17-.06l1.28-1.15s.08-.11.08-.17a.28.28 0 0 0-.06-.18ZM2.64 2.21h7.89c.32 0 .58.26.58.58v1H2.06v-1c0-.32.26-.58.58-.58Zm0 6.97a.58.58 0 0 1-.58-.58V4.28h9.05v4.28L8.1 7.18c-.09-.04-.2-.03-.27.04s-.1.17-.07.26l.56 1.68H2.64Zm9.96 4.27-1.81-2.01a.256.256 0 0 0-.19-.08h-.03c-.08.01-.15.06-.19.13l-.47.89-1.5-4.49 4.31 1.97-.93.37c-.08.03-.13.1-.15.17-.02.08 0 .16.06.22l1.81 2.01-.91.81Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default ClickWebIcon;
