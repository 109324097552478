import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ChatsIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="m4.38 8.3-1.19.48.29-.52a.52.52 0 0 0-.12-.65c-.69-.55-1.08-1.35-1.08-2.19 0-1.67 1.72-3.03 3.83-3.03s3.83 1.36 3.83 3.03v.42l1.02.09v-.51c0-2.23-2.17-4.05-4.85-4.05S1.25 3.19 1.25 5.43c0 1.01.41 1.97 1.14 2.69l-.82 1.43c-.11.19-.08.42.06.59.1.11.24.18.38.18.06 0 .13-.01.19-.04l2.39-.96c.21.06.42.1.64.13l.32.08.2-.99s-.83-.19-1.04-.25a.564.564 0 0 0-.34.01Z"
                fill={color}
            />
            <path
                d="M12.79 11.9c.63-.57.96-1.29.96-2.11 0-1.8-1.75-3.32-3.83-3.32S6.09 7.99 6.09 9.79c0 1.08.59 2.11 1.57 2.75 1.06.69 2.37.81 3.54.33l1.94.73s.06.02.09.02c.07 0 .14-.03.19-.08.07-.08.09-.19.04-.28l-.68-1.35Z"
                fill={color}
            />
        </svg>
    );
});

export default ChatsIcon;
