import { useRef, useState, useLayoutEffect, RefObject } from 'react';

export const useHasOverflowed = <T extends HTMLElement>(): [RefObject<T>, boolean] => {
    const [hasOverflowed, setHasOverflowed] = useState(false);
    const ref = useRef<T>(null);

    useLayoutEffect(() => {
        let hasOverflowedUpdated = false;

        const node: HTMLElement | null = ref?.current as unknown as HTMLElement;
        if (
            (node?.clientWidth ?? 0) - (node?.scrollWidth ?? 0) < 0 ||
            (node?.clientHeight ?? 0) - (node?.scrollHeight ?? 0) < 0
        ) {
            hasOverflowedUpdated = true;
        }

        setHasOverflowed(hasOverflowedUpdated);
    }, []);

    return [ref, hasOverflowed];
};
