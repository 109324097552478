export const capitalizeFirstLetter = (str = '') => {
    if (typeof str !== 'undefined' && str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return '';
};

export const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\\-_']/g, '\\$&'); // $& means the whole matched string

export const stringIncludes = (string = '', searchText = '') => string.toLowerCase().includes(searchText.toLowerCase());

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
export const pascalToKebabCase = (str: string) => str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
export const pascalToSnakeCase = (str: string) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).replace(/^_/, '');
export const snakeToCamelCase = (str: string) => str.replace(/_\w/g, (m) => m[1].toUpperCase());
export const toPascalCase = (str: string) => str.replace(/(^\w|-\w)/g, (str) => str.replace(/-/, '').toUpperCase());

export const pascalToSnakeCaseWithMultipleCapitalLetters = (str: string) => {
    const separatedWords = str.replace(/\W+/g, ' ').split(/ |\B(?=[A-Z])/);

    const snakeCaseWord = separatedWords.reduce((acc, word) => {
        if (acc.length === 0) {
            return word.toLowerCase();
        }
        if (word.length === 1) {
            return acc + word.toLowerCase();
        }
        return `${acc}_${word.toLowerCase()}_`;
    }, '');

    return snakeCaseWord.endsWith('_')
        ? snakeCaseWord.slice(0, snakeCaseWord.length - 1).replaceAll('__', '_')
        : snakeCaseWord.replaceAll('__', '_');
};

type CapitalizeAllFirstLettersOptions = {
    keepLettersThatAreUppercase?: boolean;
    skipWordsThatAreLessThan?: number;
    alwaysUppercaseAfterDot?: boolean;
};

const capitalizeAllFirstLettersDefaultOptions = {
    skipWordsThatAreLessThan: 2,
    alwaysUppercaseAfterDot: true,
    keepLettersThatAreUppercase: false,
} satisfies CapitalizeAllFirstLettersOptions;

export const capitalizeAllFirstLetters = (text: string, options: CapitalizeAllFirstLettersOptions = {}): string => {
    const { keepLettersThatAreUppercase, skipWordsThatAreLessThan, alwaysUppercaseAfterDot } = {
        ...capitalizeAllFirstLettersDefaultOptions,
        ...options,
    };

    const textSplitted = (keepLettersThatAreUppercase ? text : text.toLowerCase()).split(/\s/);

    const textWithFirstLetterUppercase = textSplitted
        .map((word) =>
            word.length <= skipWordsThatAreLessThan
                ? word
                : word.replace(/(^[a-z])|(-[a-z])/g, (fullWord) => fullWord.toUpperCase()),
        )
        .join(' ');

    if (alwaysUppercaseAfterDot) {
        const textSplittedByDotSpace = textWithFirstLetterUppercase.split(/\.\s/);

        if (textSplittedByDotSpace.length > 1) {
            return textSplittedByDotSpace
                .map((word) => word.replace(/^[a-z]/, (fullWord) => fullWord.toUpperCase()))
                .join('. ');
        }
    }

    return textWithFirstLetterUppercase;
};
