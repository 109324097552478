import { forwardRef } from 'react';
import { IconProps } from '../types';

export const PhoneCountIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M6.14 9.24c-.3-.3-.85-.32-1.21.12-.62-.52-1.23-1.13-1.79-1.79.44-.36.42-.91.12-1.21l-.66-.65c-.3-.3-.79-.3-1.09 0l-.36.36c-.37.37-.41.94-.14 1.36 1.06 1.6 2.46 3 4.06 4.06.42.28.99.23 1.36-.14l.36-.36c.3-.3.3-.79 0-1.09l-.65-.65Zm3.38-4.5a.48.48 0 0 0-.48.48V6c0 .27.22.48.48.48S10 6.26 10 6v-.78a.48.48 0 0 0-.48-.48Zm-2.38 0a.48.48 0 0 0-.48.48V6c0 .27.22.48.48.48s.48-.22.48-.48v-.78a.48.48 0 0 0-.48-.48Z"
                    fill={color}
                />
                <path
                    d="M11.82 3.34H6.9c-1.29 0-2.34 1.05-2.34 2.34S5.61 8.02 6.9 8.02h4.92c1.29 0 2.34-1.05 2.34-2.34s-1.05-2.34-2.34-2.34ZM8.05 6c0 .5-.41.91-.91.91S6.23 6.5 6.23 6v-.78c0-.5.41-.91.91-.91s.91.41.91.91V6Zm2.38 0c0 .5-.41.91-.91.91S8.61 6.5 8.61 6v-.78c0-.5.41-.91.91-.91s.91.41.91.91V6Zm1.99.92H11.2c-.07 0-.13-.03-.17-.09s-.05-.13-.03-.19c.13-.43.4-.68.63-.89.13-.12.25-.23.34-.36.07-.1.1-.17.1-.24v-.03c0-.2-.17-.37-.39-.37s-.39.16-.39.37c0 .12-.1.21-.21.21s-.21-.1-.21-.21c0-.44.37-.79.82-.79s.82.36.82.79v.03c0 .2-.09.36-.17.48-.12.18-.26.31-.4.44s-.28.26-.39.42h.88c.12 0 .21.1.21.21s-.1.21-.21.21Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default PhoneCountIcon;
