export const gaugeColors = [
    '#65ba17',
    '#8bbd00',
    '#acbf00',
    '#ccc000',
    '#eac300',
    '#f9c400',
    '#f79c00',
    '#f57200',
    '#f44400',
    '#f3000e',
    '#f20021',
] as const;
