import { forwardRef } from 'react';
import { IconProps } from '../types';

export const VideoConferenceIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M6.79 11.26c.5.38.97.37 1.49-.04.58-.48.93-1.45.93-2.53 0-.96-.77-1.74-1.72-1.74s-1.72.78-1.72 1.73.25 1.74.67 2.25l.34.33Zm1.91.45h-.01c-.76.33-1.56.3-2.39 0h-.01c-1.23 0-2.03.7-1.84 1.82l.08.53h5.93l.08-.53c.18-1.12-.61-1.81-1.84-1.82Z"
                    fill={color}
                />
                <path
                    d="M11.46.94H3.54c-.46 0-.83.37-.83.83v4.7c0 .46.37.83.83.83h1.65l.03-.38c.09-.87.53-1.29 1.4-1.29.59.21 1.16.24 1.7 0 .88 0 1.43.41 1.52 1.3v.38h1.6c.46 0 .83-.37.83-.83V1.77c0-.46-.37-.83-.83-.83ZM4.59 2.52c0 .19-.15.34-.34.34h-.71c-.19 0-.34-.15-.34-.34v-.71c0-.19.15-.34.34-.34h.71c.19 0 .34.15.34.34v.71Zm3.45 2.76c-.37.29-.71.3-1.07.03l-.24-.23c-.3-.37-.48-.94-.48-1.61s.55-1.23 1.23-1.23S8.7 2.8 8.7 3.48c0 .77-.25 1.46-.66 1.8Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default VideoConferenceIcon;
