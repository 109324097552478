import { isEmpty, isObject } from '../utils';

export const isNumericDuration = (value?: unknown) => !Number.isNaN(Number(value));

export const isNumeric = (value?: unknown) => {
    if (isObject(value) || isEmpty(value)) {
        return false;
    }
    // removing '.' representing thousands
    let result = `${value as string}`.split('.').join('');
    // removing ':' representing a duration;
    result = result.split(':').join('');
    // removing '%' from end of string
    result = result.replace('%', '');
    // replace ',' with '.'
    result = result.replace(',', '.');

    return !Number.isNaN(Number(result));
};
