import { isObject } from '../utils';

export const isEmptyPlainObject = (obj?: Record<string, unknown>) => {
    if (!isObject(obj)) {
        return false;
    }

    if (obj != null || obj !== undefined) {
        return Object.keys(obj).length === 0;
    }

    return false;
};

type KeyGetterType = (arg: Record<string, unknown>) => number | string;

export const groupedMap = (list: Record<string, unknown>[], keyGetter: KeyGetterType) =>
    list.reduce((entryMap, item) => {
        const key = typeof keyGetter === 'function' ? keyGetter(item) : item[keyGetter];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return entryMap.set(key, [...(entryMap.get(key) || []), item]);
    }, new Map());

export const groupBy = (list: Record<string, unknown>[], keyGetter: KeyGetterType) =>
    Object.values(list).reduce((result, item) => {
        const value = (typeof keyGetter === 'function' ? keyGetter(item) : item[keyGetter]) as string;

        return {
            ...result,
            [value]: [...((result[value] as unknown[]) || []), item],
        };
    }, {});
