import { forwardRef } from 'react';
import { IconProps } from '../types';

export const TerminalIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M1.19 11.18c0 .73.59 1.32 1.32 1.32h10c.72 0 1.32-.59 1.32-1.32V5.66H1.19v5.52Zm5.78-2.36h2.1c.15 0 .26.12.26.26s-.12.26-.26.26h-2.1c-.15 0-.26-.12-.26-.26s.12-.26.26-.26ZM3.89 7.69c-.1-.1-.1-.27 0-.37s.27-.1.37 0l1.32 1.32c.1.1.1.27 0 .37l-1.32 1.32c-.1.1-.27.1-.37 0s-.1-.27 0-.37l1.13-1.13L3.89 7.7ZM12.5 2.5h-10c-.73 0-1.32.59-1.32 1.32v1.32h12.63V3.82c0-.73-.59-1.32-1.32-1.32ZM3.29 4.61c-.29 0-.53-.24-.53-.53s.24-.53.53-.53.53.24.53.53-.24.53-.53.53Zm1.58 0c-.29 0-.53-.24-.53-.53s.24-.53.53-.53.53.24.53.53-.24.53-.53.53Zm1.58 0c-.29 0-.53-.24-.53-.53s.24-.53.53-.53.53.24.53.53-.24.53-.53.53Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default TerminalIcon;
