import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ClickChatIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="m1.79 11.75 1.26-2.51C2.03 8.36 1.47 7.22 1.47 6c0-2.58 2.56-4.69 5.7-4.69s5.7 2.1 5.7 4.69c0 1.22-.56 2.37-1.58 3.25l-.12.1-2.85-1.3.79 2.37-.25.06c-1.16.3-2.43.28-3.58-.06L2 11.95c-.13.06-.27-.08-.2-.21ZM7.17 1.8C4.3 1.8 1.96 3.68 1.96 6c0 1.13.55 2.19 1.55 2.99l.15.12-1 2 2.59-1.2.09.03c1 .31 2.12.35 3.15.14l-.88-2.63c-.04-.12.08-.22.19-.17l3.29 1.5c.84-.77 1.3-1.75 1.3-2.78 0-2.32-2.34-4.21-5.21-4.21Z"
                    fill={color}
                />
                <path
                    d="m11.95 13.62-1.6-1.78-.78 1.48-1-2.99.46-.15.64 1.92.57-1.09 1.87 2.08.81-.73-1.87-2.08 1.14-.46-1.17-.53.2-.44 2.2 1.01-1.55.62 1.6 1.78c.07.08.07.21-.01.28l-1.23 1.11c-.08.07-.21.07-.28-.01Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default ClickChatIcon;
