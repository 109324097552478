import { forwardRef } from 'react';
import { IconProps } from '../types';

export const CheckedListIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M8.4 4.47h3.45c.13 0 .24-.11.24-.24s-.11-.24-.24-.24H8.4c-.13 0-.24.11-.24.24s.11.24.24.24Zm3.45 6.05H8.4c-.13 0-.24.11-.24.24s.11.24.24.24h3.45c.13 0 .24-.11.24-.24s-.11-.24-.24-.24Zm-5.82-.81L4.34 11.4l-.83-.83a.248.248 0 0 0-.34 0c-.09.09-.09.25 0 .34l.98.98c.05.05.12.07.19.07s.13-.02.19-.07l1.85-1.85c.09-.09.09-.25 0-.34a.248.248 0 0 0-.34 0ZM4.15 5.29c.05.05.12.07.19.07s.13-.02.19-.07l1.85-1.85c.09-.09.09-.25 0-.34a.248.248 0 0 0-.34 0L4.35 4.79l-.83-.83a.248.248 0 0 0-.34 0c-.09.09-.09.25 0 .34l.98.98Zm9.73 1.97H1.12c-.13 0-.24.11-.24.24s.11.24.24.24h12.76c.13 0 .24-.11.24-.24s-.11-.24-.24-.24Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default CheckedListIcon;
