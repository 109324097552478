import { forwardRef } from 'react';
import { IconProps } from '../types';

export const StatsIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M7.5 4.21C3.89 4.21.91 7.18.91 10.79h4.97a1.609 1.609 0 1 1 3.22 0h4.97c0-3.61-2.97-6.59-6.59-6.59ZM2.55 9.76l-.95-.24.05-.21.95.24-.05.21Zm.47-1.24-.86-.48.11-.19.86.48-.11.19Zm.77-1.07-.7-.69.15-.16.7.69-.15.16Zm1.04-.86-.5-.85.19-.11.5.85-.19.11Zm1.15-.52-.27-.94.21-.06.27.94-.21.06Zm5.57.34.39.39-.69.7-.39-.39.69-.7Zm-1.25-.87.38.22-.49.85-.38-.22.49-.85Zm-2.69.3h-.22v-.98h.22v.98Zm1.01.12.25-.95.32.08-.25.95-.32-.08Zm.46 3.93-.3-.4 3.35-2.2-3.05 2.6Zm2.64-1.81.85-.49.33.57-.85.49-.33-.57Zm.55 1.07.94-.27.21.74-.94.27-.21-.74Z"
                fill={color}
            />
        </svg>
    );
});

export default StatsIcon;
