export function customMask(input: string, mask: string): string {
    let formattedInput = '';
    let maskIndex = 0;
    let inputSize = input.length;

    for (const maskChar of mask) {
        if (inputSize === 0) {
            break;
        }

        // Matches any non special character
        if (/\w/.test(maskChar)) {
            formattedInput += input[maskIndex];
            maskIndex += 1;
            inputSize -= 1;

            // Matches only special character
        } else if (/\W/.test(maskChar)) {
            formattedInput += maskChar;
        }
    }

    return formattedInput;
}
