import { forwardRef } from 'react';
import { IconProps } from '../types';

export const AudioIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M5.03.51c-.23 0-.42.19-.42.42v12.76c0 .23.19.42.42.42s.42-.19.42-.42V.93c0-.23-.19-.42-.42-.42ZM3.26 2.64c-.23 0-.42.19-.42.42v8.51c0 .23.19.42.42.42s.42-.19.42-.42V3.05c0-.23-.19-.42-.42-.42Zm3.55 2.83c-.23 0-.42.19-.42.42v2.84c0 .23.19.42.42.42s.42-.19.42-.42V5.89c0-.23-.19-.42-.42-.42Zm1.77-2.12c-.23 0-.42.19-.42.42v7.09c0 .23.19.42.42.42s.42-.19.42-.42v-7.1c0-.23-.19-.42-.42-.42Zm3.54 1.41c-.23 0-.42.19-.42.42v4.25c0 .23.19.42.42.42s.42-.19.42-.42V5.18c0-.23-.19-.42-.42-.42Zm-10.63 0c-.23 0-.42.19-.42.42v4.25c0 .23.19.42.42.42s.42-.19.42-.42V5.18c0-.23-.19-.42-.42-.42ZM13.9 6.18c-.23 0-.42.19-.42.42v1.42c0 .23.19.42.42.42s.42-.19.42-.42V6.6c0-.23-.19-.42-.42-.42Zm-3.55-4.25c-.23 0-.42.19-.42.42v9.93c0 .23.19.42.42.42s.42-.19.42-.42V2.34c0-.23-.19-.42-.42-.42Z"
                fill={color}
            />
        </svg>
    );
});

export default AudioIcon;
