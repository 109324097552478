import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ProfileIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M7.5 1.27c-3.43 0-6.23 2.8-6.23 6.23 0 1.59.6 3.04 1.59 4.14.63-.39 1.61-.75 2.57-1.1.23-.08.46-.17.68-.25V9.23c-.28-.17-.78-.62-.84-1.66-.22-.12-.36-.4-.36-.76 0-.33.12-.59.3-.73-.14-.49-.39-1.41.03-1.86.16-.17.39-.25.68-.24.32-.6 1.25-.82 2-.82.84 0 1.9.27 2.09 1.05.17.67-.08 1.46-.22 1.88.18.14.3.4.3.72 0 .36-.14.64-.36.76-.06 1.04-.56 1.49-.84 1.66v1.06c.25.09.49.18.73.27 1.03.37 1.93.7 2.52 1.07a6.2 6.2 0 0 0 1.58-4.13c0-3.43-2.79-6.23-6.23-6.23Z"
                    fill={color}
                />
                <path
                    d="M9.45 11.06c-.3-.11-.6-.22-.91-.33l-.17-.06V8.88l.18-.06s.67-.26.67-1.44v-.27h.27s.08-.12.08-.3-.06-.29-.09-.3h-.27v-.26c0-.07.03-.15.08-.32.11-.35.34-1.07.21-1.59-.09-.37-.76-.65-1.58-.65-.82 0-1.48.27-1.58.65l-.06.25-.25-.05c-.14-.03-.32-.04-.41.05-.22.24.02 1.09.09 1.36.05.16.07.23.07.3v.27h-.27s-.08.12-.08.3.06.29.09.3h.27v.26c0 1.18.64 1.43.67 1.44l.17.06v1.78l-.17.06c-.28.11-.57.21-.86.31-.8.29-1.62.59-2.2.9-.06.03-.11.06-.17.09 1.12 1.05 2.61 1.7 4.26 1.7s3.15-.65 4.27-1.71c-.05-.03-.11-.06-.17-.09-.55-.29-1.33-.57-2.16-.87Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default ProfileIcon;
