export const checkInView = (container: HTMLElement, element: HTMLElement, partial: boolean) => {
    // Get container properties
    const cTop = container.scrollTop;
    const cBottom = cTop + container.clientHeight;

    // Get element properties
    const eTop = element.offsetTop;
    const eBottom = eTop + element.clientHeight;

    // Check if in view
    const isTotal = eTop >= cTop && eBottom <= cBottom;
    const isPartial = partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));

    // Return outcome
    return isTotal || isPartial;
};

export const isValidSelector = (selector: string) => {
    try {
        document.createDocumentFragment().querySelector(selector);
    } catch {
        return false;
    }
    return true;
};

export const toggleClass = (element: HTMLElement, classNames = '') =>
    (classNames.split(' ') || []).forEach((name) => element?.classList?.toggle?.(name));
