import { forwardRef } from 'react';
import { IconProps } from '../types';

export const LighthouseIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M11.3 8.8H7.8c-.1 0-.1 0-.2-.1 0-.1-.1-.1-.1-.2l.3-2.2c0-.1.1-.2.2-.2h3c.1 0 .2.1.2.2l.3 2.2c0 .1 0 .1-.1.2 0 .1 0 .1-.1.1zM8 8.4h3l-.2-1.7H8.3L8 8.4zm3.9 4.6c-.1 0-.2-.1-.2-.2l-.2-1.7H7.7l-.1 1.1c0 .1-.1.2-.3.2-.1 0-.2-.1-.2-.3l.2-1.3c0-.1.1-.2.2-.2h4.2c.1 0 .2.1.2.2l.3 1.9c-.1.1-.2.3-.3.3z"
                    fill={color}
                />
                <path
                    d="M11.6 11.1H7.5c-.1 0-.1 0-.2-.1 0-.1-.1-.1-.1-.2l.3-2.2c0-.1.1-.2.2-.2h3.6c.1 0 .2.1.2.2l.3 2.2c0 .1 0 .1-.1.2 0 0 0 .1-.1.1zm-3.9-.5h3.6l-.2-1.7H8l-.3 1.7zm3.9-4H7.5c-.1 0-.2-.1-.2-.2v-1c0-.1.1-.2.2-.2h4.2c.1 0 .2.1.2.2v1c0 .1-.2.2-.3.2zm-3.9-.5h3.7v-.5H7.7v.5zM11 3.8H8.1c-.1 0-.2-.1-.2-.2v-.2C7.8 2.8 8.3 2 9.6 2c1 0 1.7.6 1.7 1.4v.1c-.1.2-.2.3-.3.3zm-2.7-.5h2.4c-.1-.5-.6-.8-1.2-.8s-1.1.3-1.2.8z"
                    fill={color}
                />
                <path
                    d="M10.8 5.6H8.3c-.1 0-.2-.1-.2-.2V3.5c0-.1.1-.2.2-.2h2.4c.1 0 .2.1.2.2v1.8c.1.2 0 .3-.1.3zm-2.2-.5h2V3.8h-2v1.3zm-6.2 8.1H2c-.1 0-.2-.2-.2-.3s.2-.2.3-.2c1 .2 1.8-.9 1.8-.9.1-.1.3-.1.4 0 0 0 .5.9 1.5.9s1.4-.8 1.5-.9c.1-.1.3-.2.4 0 0 0 .5.9 1.5.9s1.4-.8 1.5-.9c.1-.1.3-.2.4 0 0 0 .7 1 1.6.8.1 0 .3.1.3.2s-.1.3-.2.3c-.9.2-1.6-.4-1.9-.8-.3.3-.8.8-1.7.8s-1.4-.5-1.7-.8c-.3.3-.8.8-1.7.8s-1.4-.5-1.7-.8c-.3.3-.9.9-1.7.9zm2.8-9.3c-.1 0-.1 0-.2-.1l-.6-.5c-.1-.1-.1-.2 0-.3s.2-.1.3 0l.5.4.5-.4c.1-.1.2-.1.3 0s.1.3 0 .3l-.6.6h-.2zM3.5 5.5c-.1 0-.1 0-.2-.1l-.6-.6c-.1-.1-.1-.2 0-.3.1-.1.2-.1.3 0l.5.5.5-.6c.1-.1.3-.1.3 0 .1.1.1.3 0 .3l-.6.7c-.1 0-.1.1-.2.1zm6.1-3c-.1 0-.2-.1-.2-.2V2c0-.1.1-.2.2-.2s.2.1.2.2v.3c0 .1-.1.2-.2.2z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default LighthouseIcon;
