import { forwardRef } from 'react';
import { IconProps } from '../types';

export const CoremediaIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 170.435 39.891"
                width="1em"
                height="1em"
                fill="none"
                {...props}
                ref={forwardedRef}
            >
                <path
                    fill={color}
                    d="M24.1 29.799a6.361 6.361 0 0 1 7 1.314l2.943 2.943a19.956 19.956 0 1 1 0-28.221L31.1 8.778a6.361 6.361 0 0 1-7 1.312 10.7 10.7 0 1 0 0 19.71m-.869-9.895a3.306 3.306 0 1 0-3.306 3.306 3.31 3.31 0 0 0 3.306-3.306"
                />
                <path
                    fill={color}
                    d="M80.6 11.282v-.022h-.05V.023h.05V0h11.237v2.353H82.9v2.112h6.763v2.353H82.9v2.111h8.933v2.353Zm-34.468 0v-.022h-.05V.023h.05V0h11.241v2.353h-8.935v2.112H55.2v2.353h-6.762v2.111h8.935v2.353Zm-43.806 0L0 8.954V2.329L2.329 0h8.86v2.329H3.15l-.825.825v4.915l.891.885h7.974v2.329Zm37.656-.012L35.911 7.2h2.981l4.074 4.074Zm36.391-.012h-2.351V3.131l-.752-.752h-3.119v8.885H67.8V2.377h-4.179v8.885h-2.356V.023h12.914l2.2 2.2v9.036Zm50.866 0V7.007h-6.833v4.251h-2.353V.023h8.96v.018h.219l2.335 2.336v.38h.026v8.5Zm0-6.6v-1.48h-.008a7.232 7.232 0 0 1-.677-.8h-6.144v2.276Zm-15.867 6.608V.023h2.356v11.236Zm-7.035 0h-6.552V8.905h6.574a9.805 9.805 0 0 1 .762-.659V3.159a6.393 6.393 0 0 1-.66-.782h-6.678V.023h6.833v.018h.523l2.336 2.336v6.546l-2.336 2.336Zm-8.908 0V.023h2.353v11.236Zm-64.911 0V.023H39.1v.018h.523l2.336 2.336V7.2h-9.085v4.062ZM39.6 4.844V3.159a6.375 6.375 0 0 1-.66-.782h-6.066v2.467Zm-13.485 6.417H14.653V.023h11.692v11.236Zm-2.126-2.353V2.377h-6.98v6.529Z"
                    transform="translate(40.838 14.306)"
                />
            </svg>
        );
    },
);

export default CoremediaIcon;
