import { forwardRef } from 'react';
import { IconProps } from '../types';

export const TelephoneIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M12.66 4.28H6.91v-.32c0-.72-.59-1.31-1.31-1.31H4.2v-1.4c0-.13-.1-.23-.23-.23s-.23.1-.23.23v1.42c-.62.11-1.09.65-1.09 1.29v.32h-.32c-.42 0-.77.35-.77.77v7.06c0 .43.35.77.77.77h.34c.11.62.65 1.09 1.29 1.09h1.63c.65 0 1.19-.47 1.29-1.09h5.77c.42 0 .77-.35.77-.77V5.05a.77.77 0 0 0-.77-.77ZM2.02 12.12V5.06c0-.17.14-.32.32-.32h.32v7.7h-.32c-.17 0-.32-.14-.32-.32Zm3.58 1.4H3.97c-.47 0-.86-.39-.86-.86V3.97c0-.47.39-.86.86-.86H5.6c.47 0 .86.39.86.86v8.69c0 .47-.39.86-.86.86Zm7.38-1.4c0 .17-.14.32-.32.32H6.91v-7.7h5.75c.17 0 .32.14.32.32v7.06Z"
                    fill={color}
                />
                <path
                    d="M8.32 6.91h3.26c.13 0 .23-.1.23-.23V5.59c0-.13-.1-.23-.23-.23H8.32c-.13 0-.23.1-.23.23v1.09c0 .13.1.23.23.23Zm.22-1.08h2.81v.63H8.54v-.63Zm-.5 2.21h.54v.54h-.54zm1.63 0h.54v.54h-.54zm1.63 0h.54v.54h-.54zM8.04 9.13h.54v.54h-.54zm1.63 0h.54v.54h-.54zm1.63 0h.54v.54h-.54zm-3.26 1.09h.54v.54h-.54zm1.63 0h.54v.54h-.54zm1.63 0h.54v.54h-.54z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default TelephoneIcon;
