import { forwardRef } from 'react';
import { IconProps } from '../types';

export const OutgoingCallIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="m10.76 13.09.12-.8c.28-1.69-.92-2.73-2.78-2.74h-.02c-1.15.5-2.36.45-3.6 0h-.02c-1.86.01-3.06 1.05-2.78 2.74l.12.8h8.96Zm-.63-4.96c.14.14.38.14.52 0l.98-.98v1.96a.37.37 0 1 0 .74 0V7.16l.98.98c.14.14.38.14.52 0s.14-.38 0-.52l-1.6-1.6s-.01 0-.02-.01c-.03-.02-.06-.05-.09-.06a.365.365 0 0 0-.15-.03c-.1 0-.19.04-.26.11l-1.61 1.61c-.14.14-.14.38 0 .52ZM4.05 4.22c.02-.12.12-.2.24-.2 1.41 0 1.64-.4 1.8-.66.05-.08.09-.15.16-.22.1-.1.25-.1.35 0 .07.07.11.14.16.22.16.26.39.66 1.8.66.12 0 .22.08.24.2L9 5.24s.06.02.09.04l.17-1.27s.06-.72-.47-1.31c-.47-.52-1.27-.78-2.36-.78s-1.89.26-2.36.78c-.53.58-.47 1.31-.47 1.31l.17 1.26s.06-.03.09-.03l.2-1.02Z"
                    fill={color}
                />
                <path
                    d="M8.79 5.7c-.12 0-.22-.08-.24-.2l-.2-.99c-1.23-.03-1.67-.38-1.93-.75-.26.37-.7.72-1.93.75l-.2.99c-.02.12-.12.2-.24.2-.23 0-.29.3-.29.46s.06.46.29.46c.14 0 .25.11.25.25 0 1.29 1.57 2.12 2.12 2.12s2.12-.83 2.12-2.12c0-.14.11-.25.25-.25.23 0 .29-.3.29-.46s-.06-.46-.29-.46Z"
                    fill={color}
                />
                <path
                    d="M8.79 6.63c-.14 0-.25.11-.25.25C8.54 8.17 6.96 9 6.42 9S4.3 8.17 4.3 6.88c0-.14-.11-.25-.25-.25-.23 0-.29-.3-.29-.46s.06-.46.29-.46c.12 0 .22-.08.24-.2l.2-.99c1.23-.03 1.67-.38 1.93-.75.26.37.7.72 1.93.75l.2.99c.02.12.12.2.24.2.23 0 .29.3.29.46s-.06.46-.29.46Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default OutgoingCallIcon;
