import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ClickCellphoneIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M5.6 2.45h2.37c.12 0 .22-.1.22-.22s-.1-.22-.22-.22H5.6c-.12 0-.22.1-.22.22s.1.22.22.22Zm1.28 8.44c-.22 0-.4.18-.4.4s.18.4.4.4.4-.18.4-.4-.18-.4-.4-.4Z"
                    fill={color}
                />
                <path
                    d="m13.18 12.65-1.51-1.68 1-.4c.08-.03.14-.11.14-.2 0-.09-.05-.17-.13-.2L10.1 8.99V2.24c0-.64-.52-1.17-1.17-1.17H4.66c-.64 0-1.17.52-1.17 1.17v9.01c0 .64.52 1.17 1.17 1.17h4.41l.26.77c.03.08.1.14.19.15.09 0 .17-.04.21-.12l.5-.95 1.51 1.68s.1.07.16.07c.05 0 .1-.02.15-.06l1.12-1s.07-.09.07-.15-.02-.11-.06-.16ZM7.99 8.03c-.08-.04-.17-.02-.24.04s-.09.15-.06.23l.6 1.79H3.92V3.4h5.73v5.39l-1.67-.76ZM4.65 1.51h4.27c.4 0 .73.33.73.73v.73H3.92v-.73c0-.4.33-.73.73-.73Zm0 10.47c-.4 0-.73-.33-.73-.73v-.73h4.51l.49 1.46H4.65Zm7.27 1.51-1.58-1.76s-.1-.07-.16-.07h-.03c-.07 0-.13.05-.17.11l-.41.78-1.31-3.93 3.76 1.72-.81.32c-.07.03-.12.08-.13.15-.02.07 0 .14.05.2l1.58 1.76-.79.71Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default ClickCellphoneIcon;
