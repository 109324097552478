import { forwardRef } from 'react';
import { IconProps } from '../types';

export const MailIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M12.69 2.68H2.31c-.77 0-1.4.63-1.4 1.4v6.85c0 .77.63 1.4 1.4 1.4h10.37c.77 0 1.4-.63 1.4-1.4V4.08c0-.77-.63-1.4-1.4-1.4Zm-.03.23L7.5 6.75 2.55 2.91h10.11ZM2.58 5.34 7.4 8.69l-4.82 3.36V5.34Zm.22 6.75 4.67-3.27 4.84 3.27.1.07H2.7l.1-.07Zm9.73-6.75v6.71L7.56 8.69l4.97-3.36Z"
                fill={color}
            />
        </svg>
    );
});

export default MailIcon;
