import { forwardRef } from 'react';
import { IconProps } from '../types';

export const PersonLeadingIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M13.3 14.853H1.7c-.4 0-.8-.1-1.1-.4-.2-.2-.4-.6-.4-.9v-.2l4.4-1.9.8.9 3.1-1.9.8 1 5.5-3.3v5.3c0 .4-.2.8-.4 1.1-.3.2-.7.3-1.1.3zm-12.5-1.1c0 .1.1.2.2.3.2.2.4.3.6.3h11.6c.2 0 .5-.1.6-.3.2-.2.3-.4.3-.6v-4.2l-5 3-.8-1-3 1.9-.8-1-3.7 1.6zm9.1-4.117c-.2 0-.3-.2-.3-.3l.3-2.5c.2-1 .9-1.3 1.6-1.3h1.4c.6 0 1.3.3 1.5 1.3l.1.7c0 .2-.1.3-.2.4-.2 0-.3-.1-.4-.2l-.1-.7c-.1-.9-.6-1-.9-1h-1.4c-.3 0-.9.1-1 .8l-.3 2.5c0 .2-.1.3-.3.3zm-4.9 1.1s-.1 0 0 0c-.2 0-.3-.2-.3-.3l.3-1.7c.1-.8.8-1.1 1.3-1.1h1.1c.5 0 1.1.3 1.3 1.1l.1.4c0 .2-.1.3-.2.4-.2 0-.3-.1-.4-.2l-.1-.5c-.1-.6-.5-.7-.7-.7H6.3c-.3 0-.7.1-.8.7l-.2 1.7c-.1.1-.2.2-.3.2zm-4.2 1.1c-.2 0-.3-.2-.3-.3l.2-1.2c.1-.7.6-.9 1.1-.9h.9c.4 0 .9.2 1.1.9l.1.4c0 .2-.1.3-.2.4-.2 0-.3-.1-.4-.2l-.2-.6c0-.4-.3-.5-.5-.5h-.8c-.2 0-.5.1-.5.4l-.2 1.2c0 .2-.2.4-.3.4zm11.4-6.7c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm0-2.4c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9zm-5.4 4.6c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.2.5 1.2 1.2-.5 1.2-1.2 1.2zm0-1.8c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.2-.6-.6-.6zm-4.6 3.5c-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1zm0-1.3c-.2 0-.4.2-.4.4s.2.3.4.3.4-.2.4-.4-.2-.3-.4-.3z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default PersonLeadingIcon;
