import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ClickVideoIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M8.32 10.01H3.06c-.73 0-1.32-.59-1.32-1.32V2.52c0-.73.59-1.32 1.32-1.32h6.17c.73 0 1.32.59 1.32 1.32v6.31c0 .13-.1.23-.23.23s-.23-.1-.23-.23V2.52c0-.47-.39-.86-.86-.86H3.06c-.47 0-.86.39-.86.86v6.17c0 .47.39.86.86.86h5.25c.13 0 .23.1.23.23s-.1.23-.23.23Z"
                    fill={color}
                />
                <path
                    d="M7.05 7.47H3.78c-.13 0-.23-.1-.23-.23V3.97c0-.13.1-.23.23-.23h3.27c.13 0 .23.1.23.23v.85l1.1-.69c.07-.04.16-.05.23 0 .07.04.12.12.12.2v2.54a.235.235 0 0 1-.35.2l-1.1-.69v.85c0 .13-.1.23-.23.23Zm-3.03-.46h2.81V5.97a.235.235 0 0 1 .35-.2l1.1.69V4.75l-1.1.69c-.07.04-.16.05-.23 0a.24.24 0 0 1-.12-.2V4.2H4.02v2.81Zm7.83 6.79h-.01c-.06 0-.12-.03-.16-.08l-1.6-1.78-.53 1c-.04.08-.13.13-.22.12-.09 0-.17-.07-.2-.16L7.4 7.73c-.03-.09 0-.18.06-.24.07-.06.17-.08.25-.04l4.96 2.27c.08.04.14.12.13.22 0 .09-.06.17-.15.21l-1.05.42 1.6 1.78c.09.09.08.24-.02.33L12 13.74s-.1.06-.15.06Zm-1.83-2.49c.07 0 .13.03.17.08l1.67 1.86.84-.75-1.67-1.86a.227.227 0 0 1-.05-.21c.02-.07.07-.13.14-.16l.86-.34L8 8.11l1.39 4.15.43-.82c.04-.07.1-.11.18-.12h.03Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default ClickVideoIcon;
