import { forwardRef } from 'react';
import { IconProps } from '../types';

export const KioBallIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                width="1em"
                viewBox="0 0 16 16"
                fill="none"
                {...props}
                ref={forwardedRef}
            >
                <g fill={color}>
                    <path
                        d="M10.3941 1.4221C6.75752 0.0984772 2.74576 1.96919 1.42214 5.6058C0.0985251 9.24241 1.96924 13.2542 5.60585 14.5778C9.24246 15.9014 13.2542 14.0307 14.5778 10.3941C15.9015 6.75747 14.0307 2.74571 10.3941 1.4221ZM6.97393 10.819C4.16425 9.79638 2.61749 8.16923 2.16599 6.63211C2.21646 6.40572 2.27975 6.17335 2.36184 5.94782C3.49392 2.83744 6.94173 1.2297 10.0521 2.36179C13.1625 3.49387 14.7702 6.94168 13.6381 10.0521C13.5561 10.2776 13.4552 10.4963 13.3483 10.7021C12.0144 11.5894 9.77421 11.8382 6.97393 10.819Z"
                        fill={color}
                    />
                    <path
                        d="M5.01274 7.4073C5.79121 7.69064 6.72854 7.0789 7.10632 6.04094 7.48411 5.00299 7.15929 3.93187 6.38082 3.64853 5.60236 3.36519 4.66503 3.97693 4.28724 5.01488 3.90946 6.05284 4.23427 7.12396 5.01274 7.4073ZM9.61919 9.35151C10.3977 9.63485 11.335 9.02311 11.7128 7.98516 12.0906 6.9472 11.7657 5.87608 10.9873 5.59274 10.2088 5.3094 9.27147 5.92114 8.89369 6.9591 8.5159 7.99705 8.84072 9.06817 9.61919 9.35151Z"
                        fill={color}
                    />
                </g>
            </svg>
        );
    },
);

export default KioBallIcon;
