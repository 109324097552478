import { forwardRef } from 'react';
import { IconProps } from '../types';

export const IncomingCallIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M4.26 4.05h-.01c-.1 0-.17-.09-.17-.19.11-1.46 1.38-2.61 2.9-2.61s2.76 1.14 2.87 2.59c0 .1-.07.18-.17.19-.1 0-.18-.07-.19-.17-.1-1.27-1.2-2.26-2.52-2.26s-2.45 1-2.54 2.28c0 .09-.09.17-.18.17Zm1.42 2.98h-.55c-.39 0-.71-.32-.71-.71 0-.11-.09-.2-.2-.2s-.2.09-.2.2c0 .61.5 1.12 1.12 1.12h.98l-.1-.07c-.12-.09-.22-.21-.32-.33Z"
                    fill={color}
                />
                <path
                    d="M6.98 2.08c-1.18 0-2.14.96-2.14 2.15s.31 2.16.83 2.8h.92c0-.1.09-.19.2-.19h.37c.11 0 .2.09.2.2s-.08.19-.19.2c0 .1-.09.19-.2.19h-.88c.62.47 1.21.45 1.86-.06.71-.6 1.16-1.8 1.16-3.14 0-1.19-.96-2.16-2.14-2.16Zm-2.5 1.69H4.2a.76.76 0 0 0-.76.76v1.02c0 .42.34.76.76.76h.6c-.23-.6-.36-1.31-.36-2.08 0-.16.02-.31.04-.46Zm5.31 0h-.32c.03.15.04.31.04.47 0 .75-.13 1.46-.37 2.06h.64c.42 0 .76-.34.76-.76V4.52a.76.76 0 0 0-.76-.76Zm1.32 8.2.11-.73c.26-1.56-.85-2.52-2.56-2.53h-.02c-1.06.46-2.17.41-3.32 0H5.3c-1.71.01-2.82.97-2.56 2.53l.11.73h8.25Zm2.86-5.39a.344.344 0 0 0-.48 0l-.91.91v-1.8c0-.19-.15-.34-.34-.34-.19 0-.34.15-.34.34v1.8l-.9-.9c-.13-.13-.35-.13-.48 0s-.13.35 0 .48L12 8.55s.01 0 .02.01c.03.02.05.04.09.06.04.02.09.03.13.03.09 0 .18-.04.24-.1l1.48-1.48c.13-.13.13-.35 0-.48Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default IncomingCallIcon;
