import { forwardRef } from 'react';
import { IconProps } from '../types';

export const VoiceActionsIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                ref={forwardedRef}
            >
                <path
                    d="M12.4756 1.5C13.4756 4 9.99938 7.5 10.4998 8 11.0002 8.5 12.476 8.1 12.4756 8.5 12.4751 9 11.8427 10.1838 12.4751 10.5 13.4751 11 14.4751 11 14.4751 11.5 14.4751 12 13 12.5 13 13 13 13.5 13.4503 15.5 14.4751 15.5 15.5 15.5 17.4998 15 17.9998 16 18.3998 16.8 18.5249 17.5 18.5 17.5M7.76601 18.2932C4.02928 16.3452 2.57928 11.7367 4.52734 8"
                    stroke={color}
                />
                <path
                    d="M8.94134 16.038C6.45018 14.7393 5.48352 11.667 6.78223 9.17581M10.1176 13.7827C8.87206 13.1333 8.38873 11.5972 9.03809 10.3516"
                    stroke={color}
                />
            </svg>
        );
    },
);

export default VoiceActionsIcon;
