import { forwardRef } from 'react';
import { IconProps } from '../types';

export const SmsIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M5.52 12.5s-.07 0-.1-.02a.25.25 0 0 1-.16-.24v-2.11H2.75c-.8 0-1.44-.65-1.44-1.44V3.94c0-.8.65-1.44 1.44-1.44h9.49c.8 0 1.44.65 1.44 1.44v4.74c0 .8-.65 1.44-1.44 1.44H8l-2.3 2.3s-.11.08-.18.08ZM2.76 3.01c-.51 0-.93.42-.93.93v4.74c0 .51.42.93.93.93h2.77c.14 0 .26.11.26.26v1.75l1.93-1.93s.11-.08.18-.08h4.35c.51 0 .93-.42.93-.93V3.94c0-.51-.42-.93-.93-.93H2.76Z"
                fill={color}
            />
            <path
                d="M4.48 7.76c-.41 0-.75-.33-.77-.76 0-.14.1-.26.24-.27.14-.01.26.1.27.24 0 .16.12.28.26.28s.26-.13.26-.3c0-.3-.13-.38-.42-.54-.26-.14-.62-.34-.62-.86 0-.45.35-.81.78-.81s.75.33.77.76c0 .14-.1.26-.24.27-.14.01-.26-.1-.27-.24 0-.15-.12-.28-.26-.28s-.26.13-.26.3c0 .2.09.26.35.41.29.16.68.38.68.99 0 .45-.35.81-.77.81Zm6.04 0c-.41 0-.75-.33-.77-.76 0-.14.1-.26.24-.27.14-.01.26.1.27.24 0 .16.12.28.26.28s.26-.13.26-.3c0-.3-.13-.38-.42-.54-.26-.14-.62-.34-.62-.86 0-.45.35-.81.78-.81s.75.33.77.76c0 .14-.1.26-.24.27-.14.01-.26-.1-.27-.24 0-.15-.12-.28-.26-.28s-.26.13-.26.3c0 .2.09.26.35.41.29.16.68.38.68.99 0 .45-.35.81-.77.81Zm-2.19 0c-.14 0-.26-.11-.26-.26V5.84l-.36.55c-.1.14-.33.14-.43 0l-.36-.55V7.5c0 .14-.11.26-.26.26s-.26-.11-.26-.26V5c0-.11.07-.21.18-.25.11-.03.23 0 .29.1l.62.93.62-.93c.06-.09.18-.14.29-.1.11.03.18.13.18.25v2.5c0 .14-.11.26-.26.26Z"
                fill={color}
            />
        </svg>
    );
});

export default SmsIcon;
