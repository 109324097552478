import { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';

export const useShouldHidePlaceholder = (editorState: EditorState, placeholder?: string) => {
    const [shouldHide, setShouldHide] = useState<boolean>(!placeholder);

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const localShouldHide = contentState.hasText() || contentState.getBlockMap().first().getType() !== 'unstyled';

        setShouldHide(localShouldHide);
    }, [editorState]);

    return shouldHide;
};
