import { forwardRef } from 'react';
import { IconProps } from '../types';

export const PersonFilesIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M1.8 14.6c-.1 0-.2-.1-.2-.2V11c0-.4.4-.8.8-.8h3.9c.3 0 .7.4.7.8 0 .1-.1.2-.2.2s-.3-.1-.3-.2c0-.2-.1-.3-.3-.3H2.3c-.1 0-.3.1-.3.3v3.3c0 .2-.1.3-.2.3z"
                    fill={color}
                />
                <path
                    d="M4.8 14.6c-.1 0-.2-.1-.2-.2v-2.8c0-.4.4-.8.8-.8h3.9c.4 0 .8.4.8.8 0 .1-.1.2-.2.2s-.2-.1-.2-.2c0-.2-.1-.3-.3-.3h-4c-.2 0-.3.1-.3.3v2.8c0 .1-.1.2-.3.2z"
                    fill={color}
                />
                <path
                    d="M8.3 14.5c-.1 0-.2-.1-.2-.2v-1.9c0-.4.4-.8.8-.8h3.9c.4 0 .8.4.8.8v1.9c0 .1-.1.2-.2.2s-.3 0-.3-.1v-1.9c0-.2-.1-.3-.3-.3H8.9c-.2 0-.3.1-.3.3v1.9s-.2.1-.3.1zm-1.1-6h-.5c-.9-.2-1.7-.6-2.2-1.4-.6-.7-.8-1.6-.7-2.6.1-.9.6-1.7 1.4-2.3 1.5-1.1 3.7-.8 4.8.7.1.1.1.3 0 .3s-.3.1-.3 0c-1-1.3-2.9-1.6-4.2-.6-.6.5-1.1 1.2-1.2 2-.1.8.1 1.6.5 2.2S6 7.9 6.8 8c.8.1 1.6-.1 2.2-.6s1-1.1 1.1-1.9c0-.1.2-.2.3-.2s.2.1.2.3c-.1.9-.6 1.7-1.3 2.2-.6.5-1.3.7-2.1.7z"
                    fill={color}
                />
                <path d="m10.7 4.1-.3-1.4-1 .8z" fillRule="evenodd" clipRule="evenodd" fill={color} />
                <path
                    d="M7.2 5.6c-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4 1.4.6 1.4 1.4-.7 1.4-1.4 1.4zm0-2.3c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9zM8.9 8s-.1 0 0 0c-.2 0-.3-.1-.2-.3l.2-1.2v-.1c-.2-.1-.3-.1-.5-.1H6c-.2 0-.3.1-.3.1v.1l.2 1.2c0 .1-.1.3-.2.3-.3 0-.4-.1-.4-.2l-.2-1.2c0-.2 0-.4.1-.5.2-.2.5-.3.8-.3h2.5c.3 0 .6.1.7.3.1.1.2.3.1.5l-.2 1.2c0 .1-.1.2-.2.2zm-1.7 2.3c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2c-.1.1-.2 0-.2-.2zm0-1c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2c-.1.1-.2 0-.2-.2zm-2.9.5c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2-.2-.1-.2-.2zm0-1c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2c-.1.1-.2 0-.2-.2zm0-1c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2c-.1.1-.2 0-.2-.2zm6.2 3.5c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2-.2-.1-.2-.2zm0-1c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2-.2-.1-.2-.2zm0-1c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2c-.1.1-.2 0-.2-.2zm-.1-1c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2c0 .1-.1 0-.2-.2zm0-.9c0-.1.1-.2.2-.2s.2.1.2.2-.1.2-.2.2c0 0-.2-.1-.2-.2zm3 7.2H1.8c-.1 0-.2-.1-.2-.2s.1-.2.2-.2h11.6c.1 0 .2.1.2.2s-.1.2-.2.2z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default PersonFilesIcon;
