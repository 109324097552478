import { forwardRef } from 'react';
import { IconProps } from '../types';

export const FormIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M9.6 6.45H5.4c-.15 0-.26-.12-.26-.26s.12-.26.26-.26h4.2c.15 0 .26.12.26.26s-.12.26-.26.26Zm0 1.05H5.4c-.15 0-.26-.12-.26-.26s.12-.26.26-.26h4.2c.15 0 .26.12.26.26s-.12.26-.26.26Zm0 1.05H5.4c-.15 0-.26-.12-.26-.26s.12-.26.26-.26h4.2c.15 0 .26.12.26.26s-.12.26-.26.26ZM7.76 9.6H5.4c-.15 0-.26-.12-.26-.26s.12-.26.26-.26h2.36c.15 0 .26.12.26.26s-.12.26-.26.26Z"
                fill={color}
            />
            <path
                d="M12.22 2.25h-1.31V3.3h.26c.15 0 .26.12.26.26v6.56H9.07c-.15 0-.26.12-.26.26v2.36H3.83c-.15 0-.26-.12-.26-.26V3.56c0-.15.12-.26.26-.26h.26V2.25H2.78c-.15 0-.26.12-.26.26v11.02c0 .15.12.26.26.26h9.45c.15 0 .26-.12.26-.26V2.51c0-.15-.12-.26-.26-.26Zm-.78 8.4-2.1 2.1v-2.1h2.1Z"
                fill={color}
            />
            <path
                d="M10.12 2.25H8.78a1.319 1.319 0 0 0-2.58 0H4.86c-.15 0-.26.12-.26.26v1.57c0 .15.12.26.26.26h5.25c.15 0 .26-.12.26-.26V2.51c0-.15-.12-.26-.26-.26Z"
                fill={color}
            />
        </svg>
    );
});

export default FormIcon;
