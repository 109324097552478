import { forwardRef } from 'react';
import { IconProps } from '../types';

export const CartIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M13.82 2.09H11.9a.27.27 0 0 0-.27.2l-.73 2.89H1.18c-.19 0-.32.17-.26.34l1.65 4.63c.04.1.14.18.26.18h6.78l-.13.51H3.65c-.61 0-1.1.46-1.1 1.03s.49 1.03 1.1 1.03c.82 0 1.39-.84.95-1.54h4.15c-.43.7.12 1.54.95 1.54.61 0 1.1-.46 1.1-1.03 0-.46-.32-.84-.77-.98l2.08-8.29h1.71c.15 0 .27-.12.27-.26s-.12-.26-.27-.26Z"
                fill={color}
            />
        </svg>
    );
});

export default CartIcon;
