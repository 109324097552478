// Colors.ts
export const colors = {
    // Backgrounds
    white: '#ffffff',
    // Selected Text
    black: '#000000',

    // Backgrounds
    gray030: '#fafafa',
    // Backgrounds Disabled Button
    gray050: '#f2f2f2',
    // Disabled Icons / Button Background
    gray100: '#e5e5e5',
    gray150: '#dddddd',
    gray150_80: '#ddddddcc',
    gray200: '#d8d8d8',
    // Disabled Icons
    gray250: '#bfbfbf',
    gray300: '#777777',
    // Icons
    gray400: '#999999',
    // Text
    gray700: '#4c4c4c',
    // Text / Button Background
    gray850: '#262626',

    // Tags Errors
    red050: '#feeeed',
    red100: '#ffe7e5',
    // Buttons
    red500: '#f95d50',
    red600: '#c1443a',

    yellow500: '#f9b133',

    // Buttons
    green600: '#64c961',
    green500: '#72ce7a',

    turquoise500: '#00c4b3',

    purple500: '#c962dd',

    // Tags
    blue100: '#d0e2ff',
    blue200: '#b8cae8',
    blue500: '#4293f2',
    blue600: '#4d5bc2',
    blue700: '#5e75a4',
} as const;
