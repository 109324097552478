import { forwardRef } from 'react';
import { IconProps } from '../types';

export const CalendarIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M9.76 11.68c.51 0 .92-.43.92-.95s-.41-.95-.92-.95-.92.43-.92.95.41.95.92.95ZM7.22 6.63h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Zm2.13 2.19h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Zm0-2.19h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Z"
                    fill={color}
                />
                <path
                    d="M13.44 1.72h-2.51v.65c.18.11.29.33.23.57-.04.18-.18.32-.35.37a.508.508 0 0 1-.64-.5c0-.18.1-.33.23-.43v-.67H4.58v.66c.17.11.28.32.22.57-.04.18-.18.32-.35.37a.508.508 0 0 1-.64-.5c0-.19.1-.34.24-.43v-.66H1.56c-.28 0-.51.23-.51.52v10.19c0 .47.37.85.83.85h11.25c.46 0 .83-.38.83-.85V2.24c0-.29-.23-.52-.51-.52Zm-.11 10.43c0 .27-.21.49-.47.49H2.14a.48.48 0 0 1-.47-.49V4.44h11.67v7.71Z"
                    fill={color}
                />
                <path
                    d="M11.48 6.63h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Zm0 2.19h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Zm-4.26 0h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Zm-4.26 0h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27ZM5.09 11h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Zm-2.13 0h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Zm2.13-4.37h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27ZM7.22 11h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27ZM5.09 8.82h.81c.15 0 .27-.12.27-.27s-.12-.27-.27-.27h-.81c-.15 0-.27.12-.27.27s.12.27.27.27Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default CalendarIcon;
