import { forwardRef } from 'react';
import { IconProps } from '../types';

export const NotepadIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M11.95 3.44h1.38v8.83h-1.38zm1.39 9.24h-1.39v1c.02.06.3.29.69.29.4 0 .67-.22.69-.3v-.99Zm-.7-11.64-.62 2h1.24l-.62-2zm-1.09 11.44v-.01l-.01.01h.01zm-8.01.73h6.8V2.2h-6.8v11.01ZM4.93 5c0-.11.09-.2.2-.2h3.61c.11 0 .2.09.2.2v1.8a.2.2 0 0 1-.2.2H5.13a.2.2 0 0 1-.2-.2V5Z"
                    fill={color}
                />
                <path
                    d="M5.29 5.13h3.32V6.7H5.29zM1.66 2.6v10.22c0 .22.18.4.4.4h1.07V2.2H2.06c-.22 0-.4.18-.4.4Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default NotepadIcon;
