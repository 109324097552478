import { forwardRef } from 'react';
import { IconProps } from '../types';

export const PersonsIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <circle cx="11.95" cy="5.54" r="1.09" fill={color} />
                <circle cx="7.49" cy="5.54" r="1.09" fill={color} />
                <path
                    d="M3.05 6.63c.6 0 1.09-.49 1.09-1.09s-.49-1.09-1.09-1.09-1.09.49-1.09 1.09.49 1.09 1.09 1.09Zm6.66-2.61c.6 0 1.09-.49 1.09-1.09s-.49-1.09-1.09-1.09-1.09.49-1.09 1.09.49 1.09 1.09 1.09Z"
                    fill={color}
                />
                <circle cx="5.27" cy="2.93" r="1.09" fill={color} />
                <path
                    d="M12.8 7.24h-.65v1.49c0 .1-.08.19-.19.19s-.19-.08-.19-.19V7.24h-.65c-.46 0-.83.37-.83.83l.14 1.56c.07.54.29.96.82.96v1.9c0 .37.3.67.67.67h.09c.37 0 .67-.3.67-.67v-1.9c.53 0 .69-.42.81-.96l.15-1.56c0-.46-.37-.83-.83-.83Zm-4.47 0h-.65v1.49c0 .1-.08.19-.19.19s-.19-.08-.19-.19V7.24h-.65c-.46 0-.83.37-.83.83l.14 1.56c.07.54.29.96.82.96v1.9c0 .37.3.67.67.67h.09c.37 0 .67-.3.67-.67v-1.9c.53 0 .69-.42.81-.96l.15-1.56c0-.46-.37-.83-.83-.83Zm-4.44 0h-.65v1.49c0 .1-.08.19-.19.19s-.19-.08-.19-.19V7.24h-.65c-.46 0-.83.37-.83.83l.14 1.56c.07.54.29.96.82.96v1.9c0 .37.3.67.67.67h.09c.37 0 .67-.3.67-.67v-1.9c.53 0 .69-.42.81-.96l.15-1.56c0-.46-.37-.83-.83-.83ZM5.26 4.7c-.1 0-.19.08-.19.19v1.49c0 .1.08.19.19.19s.19-.08.19-.19V4.89c0-.1-.08-.19-.19-.19Zm4.46 0c-.1 0-.19.08-.19.19v1.49c0 .1.08.19.19.19s.19-.08.19-.19V4.89c0-.1-.08-.19-.19-.19Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default PersonsIcon;
