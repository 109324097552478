import { forwardRef } from 'react';
import { IconProps } from '../types';

export const CmccStudioIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 42 28"
                width="1em"
                height="1em"
                fill="none"
                {...props}
                ref={forwardedRef}
            >
                <mask id="a" fill="#fff">
                    <path d="M37 13.786c0 .224-.005.448-.016.67a6.5 6.5 0 0 1-1.484 12.83H9a9 9 0 1 1 1.712-17.838C12.518 4.12 17.562.286 23.5.286c7.456 0 13.5 6.044 13.5 13.5Z" />
                </mask>
                <path
                    fill={color}
                    d="m36.984 14.456-1.998-.098-.081 1.666 1.624.38.455-1.948ZM10.712 9.448l-.378 1.964 1.712.33.56-1.652-1.894-.642Zm28.27 5.105c.012-.254.018-.51.018-.767h-4c0 .192-.005.383-.014.572l3.995.195ZM44 20.786c0-4.03-2.802-7.4-6.562-8.278l-.91 3.895A4.502 4.502 0 0 1 40 20.786h4Zm-8.5 8.5a8.5 8.5 0 0 0 8.5-8.5h-4a4.5 4.5 0 0 1-4.5 4.5v4Zm0-4h-12v4h12v-4Zm-12 0H9v4h14.5v-4Zm-25.5-7c0 6.075 4.925 11 11 11v-4a7 7 0 0 1-7-7h-4Zm11-11c-6.075 0-11 4.925-11 11h4a7 7 0 0 1 7-7v-4Zm2.09.198A11.047 11.047 0 0 0 9 7.286v4c.458 0 .903.043 1.334.126l.756-3.928ZM23.5-1.714c-6.822 0-12.609 4.405-14.682 10.52l3.788 1.285c1.54-4.542 5.838-7.805 10.894-7.805v-4Zm15.5 15.5c0-8.56-6.94-15.5-15.5-15.5v4c6.351 0 11.5 5.148 11.5 11.5h4Z"
                    mask="url(#a)"
                />
            </svg>
        );
    },
);

export default CmccStudioIcon;
