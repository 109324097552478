import { forwardRef } from 'react';
import { IconProps } from '../types';

export const VideoIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M13.7 5.34H1.3c-.15 0-.27.12-.27.27v6.8c0 .56.47 1.02 1.04 1.02h10.86c.58 0 1.04-.46 1.04-1.02v-6.8c0-.15-.12-.27-.27-.27Zm-.26 7.07c0 .27-.23.49-.51.49H2.07c-.28 0-.51-.22-.51-.49V5.88h11.87v6.53Z"
                fill={color}
            />
            <path
                d="M5.47 11.77s.09.04.14.04c.04 0 .08 0 .12-.03l4.32-2.16c.09-.05.15-.14.15-.24s-.06-.19-.15-.24L5.73 6.98c-.08-.04-.18-.04-.26.01s-.13.13-.13.23v4.32c0 .09.05.18.13.23Zm.41-4.11 3.45 1.72-3.45 1.72V7.65Zm8.09-5.29c0-.45-.36-.81-.81-.81H1.84c-.45 0-.81.36-.81.81v2.16c0 .15.12.27.27.27h12.41c.15 0 .27-.12.27-.27V2.37ZM4.81 2.1 2.56 4.26h-.95L3.86 2.1h.95Zm-3.25.27c0-.15.12-.27.27-.27h.86L1.56 3.19v-.82Zm4.41-.27h1L4.72 4.26h-1L5.97 2.1Zm2.15 0h1L6.87 4.26h-1L8.12 2.1Zm2.16 0h1L9.03 4.26h-1l2.25-2.16Zm2.16 0h.72c.07 0 .13.03.17.07l-2.16 2.09h-1l2.25-2.16Zm1 2.17h-1.1l1.1-1.07v1.07Z"
                fill={color}
            />
        </svg>
    );
});

export default VideoIcon;
