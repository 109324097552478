import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ConferenceIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="m13.61 3.53-5.32.89c-.2.03-.35.18-.35.35v5.46c0 .17.15.32.35.35l5.32.89c.27.05.53-.12.53-.35V3.88c0-.23-.25-.4-.53-.35ZM8.97 6.49c0-.19.14-.36.31-.39.06-.7.65-1.35 1.41-1.49.85-.16 1.64.35 1.74 1.18.25 0 .45.18.45.41v.62c0 .22-.17.4-.4.45v.02c0 .37-.33.65-.72.64l-.58-.02c-.35.24-.67.22-1-.06-.35-.31-.56-.91-.56-1.57 0-.59.46-1.14 1.08-1.24.66-.1 1.25.36 1.25 1.04s-.19 1.25-.51 1.61h.33c.25 0 .45-.17.45-.4h-.25c.15-.35.23-.77.23-1.21 0-.09-.01-.18-.03-.27h.02c-.09-.7-.77-1.14-1.5-1.01-.66.11-1.18.67-1.25 1.28-.01.08-.02.15-.02.23 0 .37.06.71.17 1.01H9.3c-.19 0-.33-.15-.33-.35v-.48Zm4.41 3.88-.08.44-4.56-.84-.05-.35c-.1-.73.36-1.14 1.14-1.09.54.28 1.13.31 1.8.13 1.11.09 1.92.75 1.73 1.7ZM6.71 4.42l-5.32-.89c-.27-.05-.53.12-.53.35v7.24c0 .23.25.4.53.35l5.32-.89c.2-.03.35-.18.35-.35V4.77c0-.17-.15-.32-.35-.35Zm-3.76.57c.28-.23.72-.3 1.3-.2.53.09.9.28 1.11.55.23.3.2.63.2.63l-.07.57-.04-.02-.09-.47c-.01-.05-.05-.1-.11-.1-.65-.07-.77-.27-.85-.41-.02-.04-.05-.08-.08-.12-.05-.05-.13-.06-.18-.02-.03.03-.06.06-.08.1-.08.12-.21.31-.99.23-.07 0-.13.03-.14.09l-.12.54s-.04.01-.05.02l-.11-.7s-.04-.4.29-.67Zm2.54 1.96c0 .07-.03.21-.12.21-.06 0-.11.05-.11.11 0 .6-.73 1.03-1.01 1.04-.28.02-1.15-.38-1.15-1.07 0-.07-.06-.13-.15-.13-.14 0-.17-.17-.17-.26s.04-.25.17-.24c.07 0 .13-.04.14-.1l.11-.52c.68.04.91-.11 1.04-.28.13.2.35.39.92.46l.09.47c.01.05.05.09.11.1.1 0 .12.15.12.22Zm.84 2.67-.05.35-4.6.85-.08-.45c-.19-.96.62-1.63 1.74-1.72.63.21 1.24.13 1.82-.13.79-.05 1.25.36 1.15 1.1Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default ConferenceIcon;
