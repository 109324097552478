import { forwardRef } from 'react';
import { IconProps } from '../types';

export const PacketIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M4.23 2.03 1.15 3.36l6.07 2.92 2.77-1.15-5.76-3.1zm-3.54 9.1c0 .11.07.22.17.26l6.07 2.76V6.77L.69 3.78v7.35Zm1.7-4.83c0-.21.22-.34.4-.26l2.84 1.42c.1.05.17.15.17.26v2.84c0 .21-.21.34-.4.26L2.56 9.4a.298.298 0 0 1-.17-.26V6.3Zm5.11.47v7.38l6.64-2.76c.1-.04.17-.15.17-.26V3.78L7.5 6.77Zm6.35-3.41L7.61.65a.305.305 0 0 0-.23 0l-2.5 1.09 5.75 3.1 3.22-1.47Z"
                fill={color}
            />
        </svg>
    );
});

export default PacketIcon;
