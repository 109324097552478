import { forwardRef } from 'react';
import { IconProps } from '../types';

export const FileExportIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="m12.2 2.8.2-.2-.2-.2-1-1c-.1-.1-.3-.1-.3 0-.1.1-.1.3 0 .3l.6.6H9.3c-.9 0-1.6.8-1.6 1.7v2.1c0 .1.1.2.2.2s.2-.1.2-.2V4c0-.6.5-1.2 1.2-1.2h2.1l-.6.6c-.1.1-.1.3 0 .3 0 0 .1.1.2.1s.1 0 .2-.1l1-.9z"
                    fill={color}
                />
                <path
                    d="M11.5 10.6H7.6c-.1 0-.2-.1-.2-.2V8.3c0-.1 0-.2-.1-.3-.1-.1-.3-.1-.4-.1h-2c-.1 0-.1 0-.2-.1 0 0-.1-.1-.1-.2V5.1c0-.4.4-.8.8-.8h1.2c.1 0 .2.1.2.2s0 .3-.2.3H5.4c-.2 0-.3.1-.3.3v2.3h1.8c.3 0 .5.1.7.3.2.1.3.3.3.6v1.8h3.6c.2 0 .3-.1.3-.3V5.1c0-.2-.1-.3-.3-.3H9.3c-.2 0-.3-.1-.3-.2s.1-.2.2-.2h2.3c.4 0 .8.3.8.8v4.7c0 .4-.3.7-.8.7z"
                    fill={color}
                />
                <path
                    d="M10.5 11.7H4.4c-.4 0-.8-.3-.8-.8V6.1c0-.4.4-.8.8-.8h.3c.1 0 .2.1.2.2s-.1.4-.2.4h-.3c-.2 0-.3.1-.3.2v4.7c0 .2.1.3.3.3h6.1c.2 0 .3-.1.3-.3v-.3c0-.1.1-.2.2-.2s.2.1.2.2v.3c.1.5-.3.9-.7.9z"
                    fill={color}
                />
                <path
                    d="M9.4 12.7H3.3c-.4 0-.8-.3-.8-.8V7.2c0-.4.4-.8.8-.8h.3c.1 0 .2.1.2.2s0 .3-.2.3h-.3c-.1 0-.3.1-.3.3v4.7c0 .2.1.3.3.3h6.1c.2 0 .3-.1.3-.3v-.3c0-.1.1-.2.2-.2s.2.1.2.2v.3c.1.4-.2.8-.7.8z"
                    fill={color}
                />
                <path
                    d="M7.6 10.6c-.1 0-.1 0-.2-.1L4.7 7.8c-.1-.1-.1-.2 0-.3.1-.1.2-.1.3 0l2.8 2.7c.1.1.1.2 0 .3 0 .1-.1.1-.2.1z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default FileExportIcon;
