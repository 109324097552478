import { forwardRef } from 'react';
import { IconProps } from '../types';

export const DownwardArrowsIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <rect width=".6" height="3.05" x="5.05" y="5.98" rx=".3" ry=".3" fill={color} />
                <path
                    d="M5.35 9.02c-.08 0-.15-.03-.21-.09L3.76 7.54a.302.302 0 0 1 0-.43c.12-.12.31-.12.43 0l1.17 1.17 1.17-1.17c.12-.12.31-.12.43 0s.12.31 0 .43L5.58 8.92c-.06.06-.14.09-.21.09Z"
                    fill={color}
                />
                <rect width=".6" height="3.05" x="9.12" y="5.98" rx=".3" ry=".3" fill={color} />
                <path
                    d="M9.43 9.02c-.08 0-.15-.03-.21-.09L7.83 7.54c-.12-.12-.12-.31 0-.43s.31-.12.43 0l1.17 1.17 1.17-1.17c.12-.12.31-.12.43 0s.12.31 0 .43L9.65 8.92c-.06.06-.14.09-.21.09Z"
                    fill={color}
                />
                <rect width=".6" height="3.05" x="3.12" y="2.91" rx=".3" ry=".3" fill={color} />
                <path
                    d="M3.42 5.96c-.08 0-.15-.03-.21-.09L1.83 4.48a.302.302 0 0 1 0-.43c.12-.12.31-.12.43 0l1.17 1.17L4.6 4.05c.12-.12.31-.12.43 0s.12.31 0 .43L3.65 5.86c-.06.06-.14.09-.21.09Z"
                    fill={color}
                />
                <rect width=".6" height="3.05" x="7.2" y="2.91" rx=".3" ry=".3" fill={color} />
                <path
                    d="M7.5 5.96c-.08 0-.16-.03-.21-.09L5.9 4.48c-.12-.12-.12-.31 0-.43s.31-.12.43 0L7.5 5.22l1.17-1.17c.12-.12.31-.12.43 0s.12.31 0 .43L7.72 5.86c-.06.06-.13.09-.21.09Z"
                    fill={color}
                />
                <rect width=".6" height="3.05" x="11.27" y="2.91" rx=".3" ry=".3" fill={color} />
                <path
                    d="M11.58 5.96c-.08 0-.16-.03-.21-.09L9.98 4.48c-.12-.12-.12-.31 0-.43s.31-.12.43 0l1.17 1.17 1.17-1.17c.12-.12.31-.12.43 0s.12.31 0 .43L11.8 5.86c-.06.06-.13.09-.21.09Z"
                    fill={color}
                />
                <rect width=".6" height="3.05" x="7.2" y="9.04" rx=".3" ry=".3" fill={color} />
                <path
                    d="M7.5 12.09c-.08 0-.15-.03-.21-.09L5.9 10.61c-.12-.12-.12-.31 0-.43s.31-.12.43 0l1.17 1.17 1.17-1.17c.12-.12.31-.12.43 0s.12.31 0 .43l-1.38 1.38c-.06.06-.14.09-.21.09Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default DownwardArrowsIcon;
