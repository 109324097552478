import { forwardRef } from 'react';
import { IconProps } from '../types';

export const WalletIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M9.4 5.4h.5v.4h-.5v-.4zM8 5.4h.5v.4H8v-.4zm-1.4 0h.5v.4h-.5v-.4zm-.9 0v.4h-.4v-.4h.4zm-1.8 0h.5v.4h-.5v-.4zm.4 5.5h-.4v-.4h.5l-.1.4zm1.4 0h-.4v-.4h.5v.4h-.1zm1.4 0h-.5v-.4h.5v.4zm1.4 0H8v-.4h.5v.4zm.9-.4h.5v.4h-.5v-.4z"
                fill={color}
            />
            <path
                d="M10.8 7.2c-.1 0-.2-.1-.2-.2V5.6c0-.4-.3-.7-.7-.7H3.7c-.7 0-1.2-.5-1.2-1.2S3 2.6 3.7 2.6h5.2c.6 0 1.2.5 1.2 1.2-.1.1-.2.2-.3.2H3.7c-.1 0-.3-.1-.3-.3s.1-.2.2-.2h5.8c0-.3-.2-.5-.5-.5H3.7c-.4 0-.7.4-.7.7s.3.7.7.7h6.2c.7 0 1.2.6 1.2 1.2v1.3c0 .2-.1.3-.3.3z"
                fill={color}
            />
            <path
                d="M9.9 11.9H3.7c-.7 0-1.2-.6-1.2-1.2V4.1c0-.1.1-.2.2-.2s.3 0 .3.2v6.6c0 .4.3.7.7.7h6.1c.4 0 .7-.3.7-.7V9.5c0-.1.1-.2.2-.2s.2.1.2.2v1.2c.2.6-.4 1.2-1 1.2z"
                fill={color}
            />
            <path
                d="M11.6 9.7H8.9c-.6 0-1.2-.5-1.2-1.2v-.7c0-.6.5-1.2 1.2-1.2h2.7c.6 0 1.2.5 1.2 1.2v.7c0 .7-.5 1.2-1.2 1.2zM8.9 7.2c-.4 0-.7.3-.7.7v.7c0 .4.3.7.7.7h2.7c.4 0 .7-.3.7-.7v-.8c0-.4-.3-.7-.7-.7 0 .1-2.7.1-2.7.1z"
                fill={color}
            />
            <circle cx="9.2" cy="8.2" r=".4" fill={color} />
        </svg>
    );
});

export default WalletIcon;
