import { forwardRef } from 'react';
import { IconProps } from '../types';

export const SupportMenuIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M3.23 6.43h.02v.01c0 .61.5 1.11 1.11 1.11h.66c-.14-.13-.28-.28-.4-.45h-.26c-.36 0-.66-.3-.66-.66v-.01h.33c-.23-.56-.37-1.24-.37-2.01 0-.15.02-.3.04-.44s-.31 0-.31 0c.14-1.35 1.33-2.4 2.76-2.4s2.6 1.05 2.73 2.4h-.29c.03.16.04.31.04.46 0 .75-.14 1.43-.37 2h.83c.41 0 .74-.33.74-.74v-.99c0-.32-.21-.59-.5-.69V4c-.12-1.61-1.52-2.87-3.19-2.87s-3.1 1.27-3.21 2.9v.02c-.26.11-.44.37-.44.67v.99c0 .41.33.74.74.74Z"
                    fill={color}
                />
                <path
                    d="M5.97 6.92h.36c.12 0 .22.1.22.22 0 .11-.08.2-.19.22-.02.11-.11.19-.22.19H5.01s.04.04.06.05c.7.59 1.41.59 2.14.02.71-.59 1.2-1.78 1.2-3.2 0-1.26-1.01-2.29-2.27-2.29S3.87 3.15 3.87 4.41c0 1.12.28 2.05.73 2.69h1.14c.02-.1.11-.18.22-.18Zm1.68 1.83h-.02c-1.03.45-2.11.4-3.22 0h-.02c-1.66 0-2.73.94-2.48 2.45l.11.71h6.13V9.72c0-.2.17-.37.37-.37h1.01c-.44-.38-1.1-.6-1.88-.6Zm.9 2.53h4.57v1.07H8.55z"
                    fill={color}
                />
                <path d="M8.55 9.75h4.57v1.07H8.55zm0 3.06h4.57v1.07H8.55z" fill={color} />
            </svg>
        );
    },
);

export default SupportMenuIcon;
