import { forwardRef } from 'react';
import { IconProps } from '../types';

export const ChatRobotIcon = forwardRef<SVGSVGElement, IconProps>(
    ({ color = 'currentColor', ...props }, forwardedRef) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
                <path
                    d="M8.29 11.27a.27.27 0 0 0-.37.12c-.01.02-.26.47-1 .47s-.99-.45-1-.47a.278.278 0 0 0-.37-.12.27.27 0 0 0-.12.37c.02.03.4.78 1.49.78s1.48-.75 1.49-.78c.07-.14.01-.3-.12-.37ZM5.05 8.68a.28.28 0 0 0-.28.28v.8c0 .15.12.28.28.28s.28-.12.28-.28v-.8a.28.28 0 0 0-.28-.28Zm4.05 0a.28.28 0 0 0-.28.28v.8c0 .15.12.28.28.28s.28-.12.28-.28v-.8a.28.28 0 0 0-.28-.28Z"
                    fill={color}
                />
                <path
                    d="M10.57 6.35H7.21v-.52h.06c.07 0 .13-.06.13-.13v-.68c0-.07-.06-.13-.13-.13h-.68c-.07 0-.13.06-.13.13v.68c0 .07.06.13.13.13h.06v.52H3.23c-.58 0-1.06.48-1.06 1.06v5.13c0 .58.48 1.06 1.06 1.06h7.33c.58 0 1.06-.48 1.06-1.06V7.41c0-.58-.48-1.06-1.06-1.06Zm.51 6.19c0 .28-.23.51-.51.51H3.24c-.28 0-.51-.23-.51-.51V7.41c0-.28.23-.51.51-.51h7.33c.28 0 .51.23.51.51v5.13Z"
                    fill={color}
                />
                <path
                    d="M13.07 1.4H8.51c-.46 0-.83.37-.83.83v1.56c0 .46.37.83.83.83h.36v1.24l1.82-1.24h2.38c.46 0 .83-.37.83-.83V2.23c0-.46-.37-.83-.83-.83Zm.28 2.39c0 .15-.12.27-.27.27h-2.55l-1.1.75v-.75h-.91c-.15 0-.27-.12-.27-.27V2.23c0-.15.12-.27.27-.27h4.56c.15 0 .27.12.27.27v1.56ZM1.37 8.55a.28.28 0 0 0-.28.28v2.26c0 .15.12.28.28.28s.28-.12.28-.28V8.83a.28.28 0 0 0-.28-.28Zm11.09 0a.28.28 0 0 0-.28.28v2.26c0 .15.12.28.28.28s.28-.12.28-.28V8.83a.28.28 0 0 0-.28-.28Z"
                    fill={color}
                />
            </svg>
        );
    },
);

export default ChatRobotIcon;
