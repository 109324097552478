export const coremediaColors = {
    white: '#ffffff',
    black: '#000000',

    grey100: '#F6F6F6',
    grey200: '#E7E7E7',
    grey300: '#D2D2D2',
    grey400: '#BCBCBC',
    grey500: '#868686',
    grey600: '#787878',
    grey700: '#3F3F3F',

    red050: '#FFEEED',
    red100: '#FFDDDB',
    red500: '#DD342B',

    yellow200: '#FFFCCC',
    yellow300: '#FFF78C',
    yellow500: '#EFDF0F',

    orange300: '#FFE0B7',
    orange500: '#ED8703',

    green200: '#D6FFE8',
    green300: '#A6FFCC',
    green400: '#7DC89E',
    green500: '#2FAC66',

    turquoise150: '#E0FFFA',
    turquoise400: '#C8F7F0',
    turquoise500: '#6FC3B8',
    turquoise600: '#268AB2',
    turquoise700: '#277A9C',

    purple200: '#F8DEFF',
    purple300: '#D278EB',
    purple500: '#672779',

    blue100: '#EFF9FF',
    blue150: '#E9F3F7',
    blue200: '#D6F0FF',
    blue300: '#94D0F2',
    blue400: '#8AB4D3',
    blue450: '#268AB2',
    blue500: '#006CAE',
    blue600: '#025B91',
} as const;
