import { forwardRef } from 'react';
import { IconProps } from '../types';

export const TrophyIcon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', ...props }, forwardedRef) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...props} ref={forwardedRef}>
            <path
                d="M12 4.1c-.1 0-.3-.1-.3-.1H11v-.3c0-.3-.2-.5-.5-.5h-6c-.3.1-.5.3-.5.5V4h-.7c-.1 0-.2.1-.3.1-.1.1-.2.3-.2.4 0 .8 0 3.1 1.9 3.2h.2c.5.3.8.6 1.1.7.4.3.7.4.7.8 0 .7-.2 1-1 1-.3 0-.5.2-.5.5v.7c0 .3.2.5.5.5h3.7c.3 0 .5-.2.5-.5v-.7c0-.3-.2-.5-.5-.5-.8 0-1-.3-1-1 0-.5.2-.7.8-1 .2-.2.5-.3.8-.6h.3c1.9-.1 1.9-2.4 1.9-3.2-.1 0-.1-.2-.2-.3zm-8.5.5H4c0 1 .2 1.8.6 2.4-.6-.1-1.1-.6-1.1-2.4zM9.7 7c-.3.4-.7.6-.9.7-.6.4-1.1.7-1.1 1.6 0 1.1.5 1.6 1.5 1.6v.4H5.8v-.4c1.3-.1 1.5-.9 1.5-1.6 0-.8-.5-1.1-.9-1.4-.4-.2-.7-.4-1.1-.8-.6-.8-.8-1.9-.7-3.2h5.8C10.5 6 10 6.8 9.7 7zm.8 0c.3-.5.5-1.2.5-2.4h.5c0 1.8-.5 2.3-1 2.4z"
                fill={color}
            />
        </svg>
    );
});

export default TrophyIcon;
